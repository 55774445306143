export const getRenewalPackages = async ({ apiClient, signal, clubId }) => {
  try {
    const response = await apiClient.get(
      `/clubs/${clubId}?$expand=availablePaymentPlans($filter=isDeleted eq false and 
      customAttributes/any(ca: ca/displayName eq 'Offline Only' and ca/rawValue eq 'False') and 
      customAttributes/any(ca: ca/displayName eq 'Is Renewal' and ca/rawValue eq 'True');
      $expand=customAttributes,availableContractDiscountDefinitions($filter=isActive eq true ))`,
      {
        signal,
      },
    );

    return response.status === 200 ? response.data.availablePaymentPlans : [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getFirstTimePackages = async ({ apiClient, signal, clubId }) => {
  try {
    const response = await apiClient.get(
      `clubs/${clubId}?$expand=availablePaymentPlans($filter=isDeleted eq false and 
      customAttributes/any(ca: ca/displayName eq 'Offline Only' and ca/rawValue eq 'False') and 
      customAttributes/any(ca: ca/displayName eq 'Is Renewal' and ca/rawValue eq 'False');
      $expand=customAttributes,availableContractDiscountDefinitions($filter=isActive eq true ))`,
      {
        signal,
      },
    );

    return response.status === 200 ? response.data.availablePaymentPlans : [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getproductTranslations = async ({ apiClient, signal }) => {
  const translations = await apiClient.get(
    `/products-translations?$filter=language eq 'en' or language eq 'ar' and isDeleted eq false`,
    {
      signal,
    },
  );
  const value = {};

  for (let i = 0; i < translations.data.value.length; i++) {
    const item = translations.data.value[i];
    if (!value[item.productId]) {
      value[item.productId] = {
        name: { en: "", ar: "" },
        description: { en: "", ar: "" },
      };
    }

    const languageKey = item.language.toLowerCase();

    value[item.productId].name[languageKey] = item.name || "";
    value[item.productId].description[languageKey] = item.description || "";
  }

  return value;
};

export const getpaymentPlanTranslations = async ({ apiClient, signal }) => {
  const response = await apiClient.get(
    `/plans-translations?$filter=language eq 'en' or language eq 'ar' and isDeleted eq false`,
    {
      signal,
    },
  );

  const translations = {};

  for (let i = 0; i < response.data.value.length; i++) {
    const item = response.data.value[i];
    if (!translations[item.paymentPlanId]) {
      translations[item.paymentPlanId] = {
        name: { en: "", ar: "" },
        description: { en: "", ar: "" },
      };
    }
    const languageKey = item.language.toLowerCase();

    translations[item.paymentPlanId].name[languageKey] = item.name || "";
    translations[item.paymentPlanId].description[languageKey] = item.description || "";
  }

  return translations;
};

export const getPtPackages = async ({ apiClient, signal }) => {
  const response = await apiClient.get("/products?$filter=categoryId eq 4 and isDeleted eq false", {
    signal,
  });

  return response.data.value;
};

export const getMemberProducts = async ({ apiClient, signal, memberId }) => {
  const response = await apiClient.get(
    `/members/products?$filter=memberId eq ${memberId} and currentQuantity gt 0&$expand=product`,
    {
      signal,
    },
  );
  return response.data.value;
};

export const getContractPayments = async ({ apiClient, signal, memberId }) => {
  const response = await apiClient.get(
    `/contract-payments?$filter=memberId eq ${memberId} and isDeleted eq false& and isRefund eq false`,
    {
      signal,
    },
  );

  return response.data.value;
};
