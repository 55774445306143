import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

import InfoCard from "../../../components/Cards/InfoCard";
import PackageCard from "../../../components/Cards/PackageCard";
import PurchaseModal from "../../../components/Modals/PurchaseModal";
import PageHeader from "../../../components/PageHeader";
import InfoCardSkeleton from "../../../components/Skeletons/InfoCardSkeleton";
import PackageCardSkeleton from "../../../components/Skeletons/PackageCardSkeleton";
import { useMemberProducts, usePtProductBundles } from "../../../hooks";

const PersonalTrainingSessions = ({
  onPaymentMethodChange,
  initiatePayment,
  onCloseModal,
  onPurchasePackage,
  payment,
}) => {
  const { t } = useTranslation();
  const { apiClient, user, userHasPendingPayments } = useOutletContext();

  const { data: purchasedProducts, isPending: pendingMemberProducts } = useMemberProducts({
    apiClient,
    memberId: user?.id,
  });

  const { data: ptPackages, isLoading: isPtPackagesLoading } = usePtProductBundles({
    apiClient,
  });

  if (user === null) return null;
  else if (!user.hasActiveContract) return <Navigate to="/" replace={true} />;

  return (
    <Box>
      {payment.selectedPackage && (
        <PurchaseModal
          isOpen={payment.modalOpen}
          isPending={payment.loading}
          onClose={onCloseModal}
          onProceed={initiatePayment}
          paymentMethod={payment.method}
          onPaymentMethodChange={onPaymentMethodChange}
          data={{
            name: payment.selectedPackage.name,
            description: payment.selectedPackage.description,
            price: payment.selectedPackage.price,
            paymentMethods: payment.selectedPackage.paymentMethods,
            startDate: payment.selectedPackage.startDate,
          }}
        />
      )}
      <PageHeader title={t("ptSessions")} />
      {purchasedProducts?.length === 0 && ptPackages?.length === 0 && !isPtPackagesLoading ? (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            height: "300px",
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderRadius: 1.5,
            borderColor: "customColors.border",
          }}
        >
          <Typography variant="subtitle1">{t("noSessionsAvailable")}</Typography>
        </Box>
      ) : null}
      {pendingMemberProducts && <InfoCardSkeleton />}
      {purchasedProducts?.length > 0 && (
        <>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="fontWeightMedium"
            sx={{ marginTop: 2, marginBottom: 1.5 }}
          >
            {t("packagesModule.currentSessions")}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            {purchasedProducts.map((item) => (
              <InfoCard
                key={item.id}
                bgColor="customColors.bg2"
                title={item.name}
                subTitle={{
                  text: t("packagesModule.myPackages"),
                  target: "/settings/subscriptions",
                }}
                data={[
                  {
                    title: t("packagesModule.packagePrice"),
                    value: { text: item.formattedPrice, isLink: false, target: null },
                  },
                  {
                    title: t("packagesModule.usage"),
                    value: {
                      text: `${item.currentQuantity}/${item.initialQuantity}`,
                      isLink: false,
                      target: null,
                    },
                  },
                  {
                    title: t("purchaseDate"),
                    value: {
                      text: item.purchaseDate,
                      isLink: false,
                      target: null,
                    },
                  },
                ]}
              />
            ))}
          </Box>
        </>
      )}
      {isPtPackagesLoading && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5, marginTop: 2 }}>
          <PackageCardSkeleton />
          <PackageCardSkeleton />
        </Box>
      )}
      {ptPackages?.length > 0 && (
        <>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="fontWeightMedium"
            sx={{ marginTop: 2, marginBottom: 1.5 }}
          >
            {t("packagesModule.purchaseSessions")}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            {ptPackages.map((item) => (
              <PackageCard
                key={item.id}
                onPurchasePackage={onPurchasePackage}
                packageData={item}
                bgColor="customColors.bg1"
                purchaseDisabled={userHasPendingPayments}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

PersonalTrainingSessions.propTypes = {
  onPaymentMethodChange: propTypes.func.isRequired,
  initiatePayment: propTypes.func.isRequired,
  onCloseModal: propTypes.func.isRequired,
  onPurchasePackage: propTypes.func.isRequired,
  payment: propTypes.object.isRequired,
};

export default PersonalTrainingSessions;
