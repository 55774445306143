import React from "react";
import { useTranslation } from "react-i18next";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, IconButton, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import propTypes from "prop-types";

import { useBranding } from "../../hooks";
import { toggleLanguage } from "../../services/internationalization/i18n";
import ThemedButton from "../ThemedButton";

const SocialMediaLinks = ({ showLangChange }) => {
  const branding = useBranding();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",

        ...(isMobile
          ? { flexDirection: "column", alignItems: "start" }
          : { justifyContent: "center" }),
      }}
    >
      {showLangChange && (
        <ThemedButton variant="simpleText" disableRipple onClick={toggleLanguage}>
          {t("lang")}
        </ThemedButton>
      )}
      {!isMobile && (
        <Typography
          variant="subtitle1"
          component="span"
          sx={{ margin: (theme) => theme.spacing(1) }}
        >
          |
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ...(isMobile && { marginTop: 1 }),
        }}
      >
        <IconButton color="primary" component={Link} href={branding?.socialMedia?.instagram || ""}>
          <InstagramIcon fontSize="medium" />
        </IconButton>
        <IconButton color="primary" component={Link} href={branding?.socialMedia?.facebook || ""}>
          <FacebookIcon fontSize="medium" />
        </IconButton>
        <IconButton href={branding?.socialMedia?.tiktok || ""}>
          <TiktokIcon />
        </IconButton>
        <IconButton color="primary" component={Link} href={branding?.socialMedia?.snapchat || ""}>
          <SnapchatIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

SocialMediaLinks.propTypes = {
  showLangChange: propTypes.bool,
};

export default SocialMediaLinks;

const SnapchatIcon = () => {
  const theme = useTheme();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="17pt"
      height="17pt"
      viewBox="0 0 295.000000 278.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,278.000000) scale(0.100000,-0.100000)"
        fill={theme.palette.primary.main}
        stroke="none"
      >
        <path
          d="M1313 2736 c-156 -30 -283 -87 -400 -181 -107 -86 -172 -158 -215
            -241 -57 -108 -69 -168 -75 -359 l-5 -170 -57 -1 c-67 -1 -141 -31 -190 -77
            -98 -93 -94 -253 7 -337 33 -27 133 -81 200 -107 24 -9 24 -9 -27 -85 -91
            -136 -194 -212 -334 -249 -80 -20 -106 -36 -143 -85 -22 -29 -29 -50 -32 -99
            -9 -137 78 -221 287 -280 73 -20 75 -21 88 -62 20 -60 79 -116 137 -133 42
            -12 63 -11 161 4 153 24 174 19 312 -70 204 -133 265 -154 439 -154 181 0 253
            25 444 150 133 87 170 98 288 82 126 -17 169 -15 219 9 46 22 79 61 98 117 12
            33 17 36 94 58 160 47 240 104 284 203 17 40 18 50 7 100 -10 45 -22 65 -59
            102 -39 39 -57 49 -118 64 -130 31 -240 114 -335 253 -27 41 -48 75 -46 76 1
            2 28 11 58 21 144 46 240 147 240 251 0 133 -96 228 -243 240 l-77 7 0 141 c0
            173 -16 255 -70 361 -111 221 -302 375 -540 435 -108 28 -295 35 -397 16z
            m347 -182 c217 -50 408 -237 451 -441 8 -41 10 -120 6 -262 -7 -227 -3 -248
            53 -271 26 -11 40 -10 93 4 99 28 134 30 162 12 54 -36 17 -80 -103 -120 -88
            -30 -129 -52 -158 -83 -68 -73 4 -259 165 -427 107 -112 203 -167 369 -212 20
            -6 9 -26 -26 -50 -29 -20 -139 -54 -222 -68 -53 -10 -71 -29 -96 -103 -15 -45
            -28 -67 -37 -65 -80 15 -193 17 -271 5 -94 -14 -120 -25 -255 -116 -40 -27
            -103 -63 -140 -81 -62 -29 -73 -31 -181 -31 -108 0 -119 2 -181 31 -37 18
            -100 54 -140 82 -170 114 -252 136 -430 113 -56 -7 -104 -9 -108 -6 -3 4 -15
            34 -26 68 -25 75 -50 94 -141 112 -129 25 -228 74 -209 104 3 6 14 11 24 11
            38 0 146 45 214 89 162 107 327 335 327 454 0 84 -38 119 -182 167 -121 40
            -161 100 -88 130 24 10 37 9 85 -9 77 -29 136 -28 172 3 l28 24 -3 214 c-4
            244 7 320 59 419 42 78 142 185 212 227 155 93 376 122 577 76z"
        />
      </g>
    </svg>
  );
};

const TiktokIcon = () => {
  const theme = useTheme();

  return (
    <svg
      fill={theme.palette.primary.main}
      width="17pt"
      height="17pt"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
    >
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </svg>
  );
};
