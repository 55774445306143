import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import CardSlider from "../../../components/CardSlider/CardSlider";
import DataComponent from "../../../components/DataComponent";
import DashboardCardSkeleton from "../../../components/Skeletons/DashboardCardSkeleton";
import ThemedButton from "../../../components/ThemedButton";
import { useMemberProducts } from "../../../hooks";

const PersonalTraining = () => {
  const { t, i18n } = useTranslation();
  const { apiClient, user } = useOutletContext();

  const { data: memberProducts, isPending: isMemberProductsPending } = useMemberProducts({
    apiClient,
    memberId: user?.id,
  });

  const cardItems = memberProducts.map((item) => (
    <Box
      key={item.id}
      sx={{
        backgroundColor: "customColors.bg1",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "17rem",
        padding: 2,
      }}
    >
      <Typography fontWeight="fontWeightMedium" variant="subtitle1">
        {item.name[i18n.language]}
      </Typography>
      <DataComponent
        title={t("packagesModule.packagePrice")}
        value={{ text: item.formattedPrice, isLink: false, target: null }}
      />
      <DataComponent
        title={t("packagesModule.usage")}
        value={{
          text: `${item.currentQuantity}/${item.initialQuantity}`,
          isLink: false,
          target: null,
        }}
      />
      <Typography variant="subtitle2" fontWeight="fontWeightMedium">
        {item.description[i18n.language]}
      </Typography>
    </Box>
  ));

  let element = <NoPtSessionsCard />;
  if (memberProducts.length > 0) element = <CardSlider cards={cardItems} />;

  return (
    <Box sx={{ flex: 1, minWidth: "18rem", marginBottom: { xs: 2, md: 0 } }}>
      <Typography variant="h5" component="h2" fontWeight="fontWeightBold" sx={{ marginBottom: 1 }}>
        {t("dashboardModule.personalTraining")}
      </Typography>
      {isMemberProductsPending && <DashboardCardSkeleton />}
      {!isMemberProductsPending && element}
    </Box>
  );
};

export default PersonalTraining;

const NoPtSessionsCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handlenavigate = () => navigate("/personal-training-sessions");

  return (
    <Box
      sx={{
        backgroundColor: "customColors.bg1",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "17rem",
        padding: 2,
      }}
    >
      <Typography variant="body2" fontWeight="fontWeightMedium">
        {t("dashboardModule.noPtSessions")}
      </Typography>
      <ThemedButton
        variant="contained"
        color="secondary"
        fullWidth
        withIcon
        onClick={handlenavigate}
        sx={{ marginTop: 1, justifyContent: "space-between" }}
      >
        {t("dashboardModule.ptSessions")}
      </ThemedButton>
    </Box>
  );
};
