import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import propTypes from "prop-types";

import { useBranding, useDir } from "../../hooks";
import { useAuth } from "../../utils/AuthProvider";
import DirectionArrow from "../DirectionArrow";
import ThemedButton from "../ThemedButton";
import NavLinkWrapper from "./NavLinkWrapper";
import PermanentDrawer from "./PermenantDrawer";
import ProfileInfo from "./ProfileInfo";
import SocialMediaLinks from "./SocialMediaLinks";

const drawerWidth = 240;

const Navigation = ({ user }) => {
  const { t, i18n } = useTranslation();
  const dir = useDir();
  const theme = useTheme();
  const branding = useBranding();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const { logout } = useAuth();
  const queryClient = useQueryClient();

  const drawerDirection = dir === "rtl" ? "right" : "left";

  const handleDrawerToggle = () => {
    setMobileDrawerOpen((prevState) => !prevState);
  };

  const handleLogout = () => logout(queryClient);

  const navItems = useMemo(() => {
    const list = [
      { text: "dashboard", link: "/" },
      { text: "packages", link: "/packages" },
      { text: "classes", link: "/classes" },
      { text: "ptSessions", link: "/personal-training-sessions" },
      { text: "mySubscriptions", link: "/settings/subscriptions" },
    ];

    if (user?.hasActiveContract) return list;
    else return list.filter((item) => item.text !== "ptSessions");
  }, [user]);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        padding: theme.spacing(4, 2),
        backgroundColor: theme.palette.customColors.layoutBg,
      }}
    >
      <Box sx={{ textAlign: "start" }}>
        <NavLink to="/">
          {branding && (
            <Box component="img" src={branding.assets?.logo} alt={branding.logoAltText} />
          )}
        </NavLink>
        <List sx={{ marginTop: theme.spacing(2) }}>
          {navItems.map((item) => (
            <Link
              variant="navlink"
              component={NavLinkWrapper}
              to={item.link}
              key={item.text}
              muiClassName={({ isActive }) => (isActive ? "active" : "")}
            >
              <ListItem disablePadding>
                <ListItemButton disableRipple sx={{ textAlign: "start", paddingInlineStart: "0" }}>
                  <ListItemText primary={t(item.text)} />
                  <DirectionArrow reverse={i18n.language === "en"} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
      <Box>
        {isMobile && <SocialMediaLinks showLangChange={!!user?.name} />}
        <Divider
          sx={{ bgcolor: "#B3B3B3", marginBottom: 2 }}
          variant="fullWidth"
          orientation="horizontal"
        />
        <Link
          variant="navlink"
          component={NavLinkWrapper}
          to="/settings"
          key="settings"
          muiClassName={({ isActive }) => (isActive ? "active" : "")}
        >
          <ListItem disablePadding>
            <ListItemButton disableRipple sx={{ textAlign: "start", paddingInlineStart: "0" }}>
              <ListItemText primary={t("settings")} />
              <DirectionArrow reverse={i18n.language === "en"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <ThemedButton fullWidth variant="white" sx={{ marginTop: 2 }} onClick={handleLogout}>
          {t("logout")}
        </ThemedButton>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar
        component="nav"
        sx={{
          paddingInlineStart: isMobile ? "0" : "19vw",
          backgroundColor: theme.palette.customColors.layoutBg,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...(!isMobile
              ? { padding: "16px 8px !important" }
              : { paddingTop: 1.5, paddingBottom: 1.5 }),
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", md: "none" }, paddingInlineStart: "16px" }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          {!isMobile && <ProfileInfo user={user} />}
          {isMobile ? (
            <NavLink to="/">
              {branding && (
                <Box component="img" src={branding.assets?.logo} alt={branding.logoAltText} />
              )}
            </NavLink>
          ) : (
            <SocialMediaLinks isMobile={isMobile} showLangChange={!!user?.name} />
          )}
        </Toolbar>
        {isMobile && (
          <Toolbar sx={{ paddingBottom: 1 }}>
            <ProfileInfo user={user} />
          </Toolbar>
        )}
      </AppBar>
      <nav>
        {isMobile ? (
          <Drawer
            variant="temporary"
            open={mobileDrawerOpen}
            onClose={handleDrawerToggle}
            anchor={drawerDirection}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { md: "block", lg: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <PermanentDrawer content={drawer} drawerDirection={drawerDirection} />
        )}
      </nav>
    </>
  );
};

Navigation.propTypes = {
  user: propTypes.object,
};

export default Navigation;
