class ApplePayIntegration {
  constructor({ config, dispatch, apiClient }) {
    this.dispatch = dispatch;
    this.apiClient = apiClient;
    this.checkoutId = null;
    this.itemName = config.itemDetails.name;
    this.amount = config.itemDetails.amount;
    this.navigate = config.navigate;
    this.checkoutPayload = {
      paymentPlanId: config.itemDetails.id,
      memberId: config.memberDetails.id,
      lang: "en",
      startDate: config.startDate,
    };
  }

  async pay() {
    try {
      this.dispatch({ type: "SET_LOADING", payload: true });

      this.setupApplePay({
        paymentFormUrl: "https://eu-prod.oppwa.com/v1/paymentWidgets.js",
        amount: this.amount,
        currency: "SAR",
      });
    } catch (error) {
      console.error("Error in payment flow:", error);
      this.dispatch({ type: "SET_ERROR", payload: "somethingWentWrong" });
    }
  }

  async checkout() {
    try {
      const response = await this.apiClient.post("/applepay/checkout", this.checkoutPayload);

      if (response.data.result.code === "000.200.100") {
        console.log("Checkout created successfully:", response.data);
        this.checkoutId = response.data.id;
        return response.data.id;
      } else {
        console.error("Failed to create checkout:", response.data.result.description);
        return response;
      }
    } catch (error) {
      console.error("Error fetching checkout data:", error);
      return null;
    }
  }

  setupApplePay({ paymentFormUrl, amount, currency }) {
    const script = document.createElement("script");
    script.src = paymentFormUrl;
    script.async = true;
    script.onload = () => {
      this.initializeWidget({ amount, currency, itemName: this.itemName });
    };
    document.body.appendChild(script);
  }

  initializeWidget({ amount, currency, itemName }) {
    window.wpwlOptions = {
      createCheckout: this.checkout.bind(this),
      applePay: {
        version: 3,
        checkAvailability: "canMakePayments",
        // merchantIdentifier: "merchant.com.activgyms.web",
        total: {
          label: itemName,
          amount: amount.toFixed(2),
        },
        shippingType: "servicePickup",
        currencyCode: currency,
        countryCode: "SA",
        // merchantCapabilities: ["supports3DS"],
        supportedNetworks: ["masterCard", "visa", "mada"],
        displayName: "MyStore",
        buttonSource: "js",
        buttonStyle: "black",
        buttonType: "buy",
        onPaymentAuthorized: this.handlePaymentAuthorization.bind(this),
        onCancel: this.handlePaymentCancellation.bind(this),
        // onPaymentMethodSelected: function (paymentMethod) {
        //   return {
        //     // newTotal: amount,
        //     // newLineItems: [],
        //   };
        // },
        // onShippingMethodSelected: function (shippingMethod) {
        //   return {
        //     // newTotal: amount,
        //     // newLineItems: [],
        //   };
        // },
        // onShippingContactSelected: function (shippingMethod) {
        //   return {
        //     // newTotal: amount,
        //     // newLineItems: [],
        //   };
        // },
      },
    };
  }

  async handlePaymentAuthorization(payment) {
    try {
      console.log("Payment authorized: ", payment);
    } catch (error) {
      console.error("Error verifying payment:", error);
      this.dispatch({ type: "SET_ERROR", payload: "somethingWentWrong" });
      // this.navigate("/order-complete?status=error");
    }
  }

  handlePaymentCancellation() {
    console.log("Payment cancelled by the user.");
    this.dispatch({ type: "CANCEL_APPLE_PAYMENT" });
  }
}

export default ApplePayIntegration;
