const en = {
  translation: {
    welcome: "Welcome",
    lang: "العربية",
    en: "English",
    ar: "Arabic",
    logout: "Logout",
    dashboard: "Dashboard",
    ptSessions: "Personal Training Sessions",
    classes: "Classes",
    packages: "Packages",
    settings: "Settings",
    mySubscriptions: "My Subscriptions",
    mins: "Mins",
    somethingWentWrong: "Something went wrong",
    trainer: "Trainer",
    branch: "Branch",
    male: "Male",
    female: "Female",
    saveChanges: "Save Changes",
    language: "Language",
    view: "View",
    confirm: "Confirm",
    cancel: "Cancel",
    purchaseDate: "Purchase Date",
    expiryDate: "Expiry Date",
    paymentMethod: "Please Select Payment Method",
    total: "Total",
    proceed: "Proceed",
    paymentRejected: "Payment Rejected",
    applePayNotSupported: "Apple Pay is not supported on this device",
    orderCompleteTitle: "Success",
    orderFailedTitle: "Error",
    orderCompleteSuccessMsg: "Your package has been purchased",
    orderCompleteFailedMsg: "Please contact your bank",
    noSessionsAvailable: "No sessions available, please subscribe to a package first",
    continue: "Continue",
    errorPageMsg: "Oops page not found!",
    qualifications: "Qualifications",
    aboutTrainer: "About the trainer",
    startDate: "Start Date",
    paymentSuccess: "Payment Success",
    paymentFailed: "Payment Failed",
    SA: "Saudi Arabia",
    error: "Error",
    login: {
      title: "Login",
      signUp: "Sign up",
      email: "Email",
      password: "Password",
      submit: "Submit",
      forgotPassword: "Forgot password?",
      forgotYourPassword: "Forgot your password?",
      backToLogin: "Back to Login",
      passwordHelperText: "Password is required",
      emailHelperText: "Email is required",
      invalidEmail: "Invalid Email",
      invalid_cred: {
        title: "Invalid Credentials",
        message:
          "There was an error with the email or password you entered, please make sure that you enter valid credentials.",
      },
      invalid_email: {
        title: "Invalid email",
        message:
          "There was an error with your email address that you entered, please make sure that you enter a valid email.",
      },
      emailSent: {
        title: "Sent Successfully",
        message:
          "An Email has been sent to you, please follow the steps in the email to reset your password.",
      },
    },
    dashboardModule: {
      subscribeToPckg: "Subscribe to a package",
      currentPackage: "Current Package",
      notSubToPckg: "You're not Subscribed to any package",
      noAppointments: "No Appointments Found",
      bookClass: "Book a class now",
      appointments: "Appointments",
      personalTraining: "Personal Training",
      ptSessions: "Personal Training Sessions",
      noPtSessions:
        "Purchase personal training sessions to boost your transformation through designing a personalized nutrition and workout plan",
      latestPayments: "Latest Payments",
      pendingPayment:
        "An Error occurred while processing your last payment, please try again after a few minutes",
    },
    calendar: {
      selectDate: "Select Date",
    },
    classesModule: {
      available: "Available",
      full: "Full",
      trainer: "Trainer",
      grpSize: "Group Size",
      time: "Time",
      duration: "Duration",
      moreDetails: "More Details",
      noClasses: "No classes available",
      searchClasses: "Search Classes",
      bookClass: "Book Class",
      youBookedThisClass: "You have booked this class",
      classBookedSuccess: "Class Booked Successfully",
    },
    settingsModule: {
      userProfile: "User Profile",
      transactionHistory: "Transaction History",
      subscriptions: "Subscriptions",
      accountSettings: "Account Settings",
      uploadPhoto: "Upload your Photo",
      yourProfilePic: "Your Profile Picture",
      name: "Name",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      dateOfBirth: "Date of Birth",
      mobileNumber: "Mobile Number",
      gender: "Gender",
      nameIsRequired: "Please enter your first and last name",
      nameIsTooShort: "Name should be longer than 3 characters",
      invalidMobile: "Mobile number should start with 05 and contain digits only",
      mobileLength: "Mobile number should be 10 digits",
      ageValidationMsg: "Age should be at least 18 years",
      nationalID: "National ID",
      natIdIsRequired: "Please enter your national ID",
      natIdLength: "National ID should be 10 digits",
      invalidNatId: "National ID should contain digits only",
      removeImg: "Remove Image",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
      currentPasswordRequired: "Current Password is required",
      enterNewPassword: "Please enter a new password",
      enterPasswordConfirm: "Please confirm your new password",
      passwordsDontMatch: "Passwords don't match",
      passwordMinLength: "Password should be at least 4 characters",
      passwordMaxLength: "Password should be less than 40 characters",
      incorrectPassword: "Incorrect password",
      passwordChanged: "Password changed successfully",
      item: "Item",
      postedDate: "Posted Date",
      amountPaid: "Amount Paid",
      paymentLocation: "Payment Location",
      paymentType: "Payment Type",
      quantity: "Quantity",
      discount: "Discount",
      ref: "Ref",
      receipt: "Receipt",
      cancelSubscription: "Cancel Subscription",
      confirmCancelSubscription:
        "Are you sure you want to cancel {{name}}? your subscription will be valid till {{expiryDate}}",
      noSubscriptions: "No Subscriptions",
      noTransactions: "No Transactions Available",
      subscriptionCancelled:
        "Subscription Cancelled Successfully, you have access till {{expiryDate}}",
      profileUpdated: "Profile updated successfully",
      personalData: "Personal Information",
      addressData: "Address Data",
      country: "Country",
      state: "State",
      city: "City",
      street: "Street",
      postalCode: "Postal Code",
      stateIsRequired: "State is required",
      cityIsRequired: "City is required",
      streetIsRequired: "Street is required",
      postalCodeIsRequired: "Postal Code is required",
      profilePictureUpdated: "Profile Picture Updated Successfully",
      deletePhotoError: "Error deleting profile picture",
      uploadPhotoError: "Error updating profile picture",
    },
    packagesModule: {
      purchasePackage: "Purchase Package",
      myPackages: "My Packages",
      packagePrice: "Package Price",
      usage: "Usage",
      currentPackages: "Current Packages",
      renewalPackages: "Renewal Packages",
      currentSessions: "Current Sessions",
      purchaseSessions: "Purchase Sessions",
    },
  },
};

export default en;
