import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useActionData, useNavigation, useSubmit } from "react-router-dom";

import { FormGroup, Link, Typography } from "@mui/material";
import axios from "axios";

import FormInput from "../../../components/Form/FormInput";
import LanguageChangeBtn from "../../../components/Login/LanguageChangeBtn";
import Message from "../../../components/Login/Message";
import ThemedButton from "../../../components/ThemedButton";
import { useBranding } from "../../../hooks";

const ForgotPassword = () => {
  const branding = useBranding();
  const { t, i18n } = useTranslation();
  const data = useActionData();
  const navigation = useNavigation();
  const submit = useSubmit();
  const { handleSubmit, control, clearErrors } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (i18n.language) clearErrors();
  }, [clearErrors, i18n.language]);

  const onSubmit = (data) => {
    submit({ ...data, url: `${branding.apiUrl}/auth/forgot-password` }, { method: "post" });
  };

  let alertMessage = null;
  if (data?.statusText) {
    alertMessage = (
      <Message
        type={data?.statusText === "Bad Request" ? "error" : "success"}
        title={t(data.title)}
        text={t(data.message)}
      />
    );
  }

  return (
    <>
      <Typography variant="h3" component="h1" fontWeight="fontWeightBold">
        {t("login.forgotPassword")}
      </Typography>

      {alertMessage}

      <FormGroup
        sx={{
          gap: "10px",
        }}
      >
        {data?.statusText !== "OK" && (
          <>
            <FormInput
              name="email"
              control={control}
              label={t("login.email")}
              type="email"
              fullWidth
              rules={{
                required: t("login.emailHelperText"),
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: t("login.invalidEmail"),
                },
              }}
            />
            <ThemedButton
              sx={{
                padding: (theme) => theme.spacing(1.8, 0),
              }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              loading={navigation.state === "submitting"}
            >
              {t("login.submit")}
            </ThemedButton>
          </>
        )}
        <Link fontWeight="fontWeightBold" component={RouterLink} to="/login">
          {t("login.backToLogin")}
        </Link>
        <LanguageChangeBtn />
      </FormGroup>
    </>
  );
};

export default ForgotPassword;

export const action = async ({ request, params }) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const url = formData.get("url");

  console.log({ email, url });

  try {
    await axios.post(url, {
      email,
    });

    return {
      statusText: "OK",
      title: "login.emailSent.title",
      message: "login.emailSent.message",
    };
  } catch (error) {
    return {
      statusText: "Bad Request",
      title: "login.invalid_email.title",
      message: "login.invalid_email.message",
    };
  }
};
