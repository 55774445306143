import React from "react";

import { Alert, Typography } from "@mui/material";
import propTypes from "prop-types";

import { check, cross } from "../../assets";

const Message = ({ type, title, text }) => {
  return (
    <Alert
      severity={type}
      icon={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "15px",
        borderRadius: " 5px",
        border: "1px solid  #B3B3B3",
        background:
          type === "error"
            ? "linear-gradient(0deg, rgba(246, 75, 75, 0.10) 0%, rgba(246, 75, 75, 0.10) 100%), #FFF"
            : "linear-gradient(0deg, rgba(43, 182, 115, 0.10) 0%, rgba(43, 182, 115, 0.10) 100%), #FFF",
      }}
    >
      <img
        src={type === "error" ? cross : check}
        alt={type === "error" ? "Error icon" : "Success icon"}
      />

      <Typography
        sx={{
          color: "primary",
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#333",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {text}
      </Typography>
    </Alert>
  );
};
export default Message;

Message.propTypes = {
  type: propTypes.oneOf(["error", "success"]).isRequired,
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
};
