import React from "react";
import { Toaster } from "react-hot-toast";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { useAnalytics, useBrandedTheme, useBranding, useSetDocumentDir } from "./hooks";
import AppRouter from "./routes/AppRouter";

import "./App.css";

function App() {
  useSetDocumentDir();
  useAnalytics();

  const branding = useBranding();
  const activTheme = useBrandedTheme();

  return (
    <ThemeProvider theme={activTheme}>
      <CssBaseline />
      {branding && <AppRouter />}
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
