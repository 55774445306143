import React from "react";

import { Box, Skeleton } from "@mui/material";

const SubscriptionCardSkeleton = () => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        width: "100%",
        padding: (theme) => theme.spacing(2),
      }}
    >
      <Skeleton variant="text" width="30%" animation="wave" sx={{marginBottom: 4}} />
      <Skeleton
        variant="rectangular"
        sx={{ marginTop: 2 }}
        width="30%"
        height={20}
        animation="wave"
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: 2 }}
          width="30%"
          height={20}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: 2 }}
          width="30%"
          height={20}
          animation="wave"
        />
      </Box>
    </Box>
  );
};

export default SubscriptionCardSkeleton;
