import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, FormGroup, IconButton, InputAdornment, Link, Typography } from "@mui/material";

import FormInput from "../../../components/Form/FormInput";
import LanguageChangeBtn from "../../../components/Login/LanguageChangeBtn";
import Message from "../../../components/Login/Message";
import ThemedButton from "../../../components/ThemedButton";
import { useAuth } from "../../../utils/AuthProvider";

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const { auth, login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, clearErrors } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (i18n.language) clearErrors();
  }, [clearErrors, i18n.language]);

  const onSubmit = async (data) => {
    login(data);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let alertMessage = null;
  if (auth.isError)
    alertMessage = (
      <Message type="error" title={t(auth.error.title)} text={t(auth.error.message)} />
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" component="h1" fontWeight="fontWeightBold" sx={{ marginBottom: 2 }}>
        {t("login.title")}
      </Typography>

      {alertMessage}

      <FormGroup
        sx={{
          gap: "10px",
          alignItems: "center",
        }}
      >
        <FormInput
          name="email"
          control={control}
          label={t("login.email")}
          type="text"
          rules={{
            required: t("login.emailHelperText"),
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: t("login.invalidEmail"),
            },
          }}
          fullWidth
        />

        <FormInput
          name="password"
          control={control}
          label={t("login.password")}
          type={showPassword ? "text" : "password"}
          rules={{ required: t("login.passwordHelperText") }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <ThemedButton
          sx={{
            padding: (theme) => theme.spacing(1.8, 0),
          }}
          variant="contained"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          loading={auth.isLoading}
          type="submit"
        >
          {t("login.title")}
        </ThemedButton>
        <Button
          sx={{
            padding: (theme) => theme.spacing(1.8, 0),
          }}
          fullWidth
          variant="outlined"
          LinkComponent={RouterLink}
          to={`https://www.activgyms.com/${i18n.language}/join/`}
        >
          {t("login.signUp")}
        </Button>
        <Link fontWeight={700} component={RouterLink} to="forgot-password">
          {t("login.forgotYourPassword")}
        </Link>
        <LanguageChangeBtn />
      </FormGroup>
    </form>
  );
};
export default LoginForm;
