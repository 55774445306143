import React from "react";

import { Box } from "@mui/material";
import propTypes from "prop-types";

import DirectionArrow from "../DirectionArrow";

const StepButton = ({ onClick, reverse }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.grey[200],
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[300],
        },
        cursor: "pointer",
        padding: 1,
        borderRadius: 1,
      }}
      variant="iconbtn"
      onClick={onClick}
    >
      <DirectionArrow fontSize="large" color="primary" reverse={reverse} />
    </Box>
  );
};

StepButton.propTypes = {
  onClick: propTypes.func.isRequired,
  reverse: propTypes.bool,
};

export default StepButton;
