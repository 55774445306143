import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthProvider } from "../utils/AuthProvider";
import ApplePayTest from "../views/ApplePayTest/ApplePayTest";
import ClassDetails from "../views/Classes/ClassDetails/ClassDetails";
import Classes from "../views/Classes/Classes";
import Dashboard from "../views/Dashboard/Dashboard";
import ErrorPage from "../views/ErrorPage/ErrorPage";
import Layout from "../views/Layout/Layout";
import ForgotPassword, {
  action as forgotPasswordAction,
} from "../views/Login/ForgotPassword/ForgotPassword";
import LoginForm from "../views/Login/LoginForm/LoginForm";
import LoginPage from "../views/Login/LoginPage";
import OrderComplete from "../views/OrderComplete/OrderComplete";
import PackagesParent from "../views/PackagesParent/PackagesParent";
import AccountSettings from "../views/Settings/AccountSettings/AccountSettings";
import Settings from "../views/Settings/Settings";
import Subscriptions from "../views/Settings/Subscriptions/Subscriptions";
import TransactionHistory from "../views/Settings/TransactionHistory/TransactionHistory";
import UserProfile from "../views/Settings/UserProfile/UserProfile";
import Trainer from "../views/Trainer/Trainer";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
    children: [
      { index: true, element: <LoginForm /> },
      { path: "forgot-password", element: <ForgotPassword />, action: forgotPasswordAction },
    ],
  },
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "order-complete", element: <OrderComplete /> },
      { path: "applepay", element: <ApplePayTest /> },
      { path: "personal-training-sessions", element: <PackagesParent route="pt-sessions" /> },
      { path: "packages", element: <PackagesParent route="packages" /> },
      { path: "classes", element: <Classes /> },
      { path: "classes/:classID", element: <ClassDetails /> },
      {
        path: "trainer/:trainerID",
        element: <Trainer />,
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          { path: "user-profile", element: <UserProfile /> },
          { path: "transaction-history", element: <TransactionHistory /> },
          { path: "subscriptions", element: <Subscriptions /> },
          { path: "account-settings", element: <AccountSettings /> },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient();

const AppRouter = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default AppRouter;
