import React from "react";

import { Box, Skeleton } from "@mui/material";
import propTypes from "prop-types";

const UserProfileSkeleton = ({ isDesktop, isMobile }) => {
  return (
    <Box sx={{ padding: (theme) => theme.spacing(2, 0) }}>
      <Skeleton
        variant="rectangular"
        width={130}
        height={130}
        animation="wave"
        sx={{ marginTop: 4, borderRadius: 1 }}
      />

      <RectangularSkeletonPair isDesktop={isDesktop} isMobile={isMobile} />
      <RectangularSkeletonPair isDesktop={isDesktop} isMobile={isMobile} />
      <Box
        sx={{
          display: "flex",
          marginTop: 4,
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={isMobile ? "100%" : "35%"}
          height={isMobile ? 40 : 50}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={isMobile ? "100%" : "35%"}
          height={isMobile ? 40 : 50}
          animation="wave"
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width={isMobile ? "100%" : "35%"}
        height={isMobile ? 40 : 50}
        animation="wave"
        sx={{ marginTop: 4 }}
      />
    </Box>
  );
};

UserProfileSkeleton.propTypes = {
  isDesktop: propTypes.bool.isRequired,
  isMobile: propTypes.bool.isRequired,
};

export default UserProfileSkeleton;

const RectangularSkeletonPair = ({ isDesktop, isMobile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 4,
        gap: isDesktop ? 10 : 3,
        flexDirection: isDesktop ? "row" : "column",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={isMobile ? "100%" : "35%"}
        height={isMobile ? 40 : 50}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width={isMobile ? "100%" : "35%"}
        height={isMobile ? 40 : 50}
        animation="wave"
      />
    </Box>
  );
};

RectangularSkeletonPair.propTypes = {
  isDesktop: propTypes.bool.isRequired,
  isMobile: propTypes.bool.isRequired,
};
