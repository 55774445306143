export const getClasses = async ({ apiClient, memberId, signal, homeClubId, startDate }) => {
  const response = await apiClient.get(
    `/classes?$filter=clubId eq ${homeClubId} and startDate eq ${startDate} and isDeleted eq false &
    $expand=classType, instructor, bookings($filter=memberId eq ${memberId} and isDeleted eq false or isCanceled eq false)`,
    {
      signal,
    },
  );
  return response.status === 200 ? response.data.value : null;
};

export const getclassTypeTranslations = async ({ apiClient, signal }) => {
  const translations = await apiClient.get(
    `/classtypes-translations?$filter=language eq 'en' or language eq 'ar' and isDeleted eq false`,
    {
      signal,
    },
  );
  const value = {};

  for (let i = 0; i < translations.data.value.length; i++) {
    const item = translations.data.value[i];
    if (!value[item.classTypeId]) {
      value[item.classTypeId] = {
        name: { en: "", ar: "" },
        description: { en: "", ar: "" },
      };
    }
    const languageKey = item.language.toLowerCase();

    value[item.classTypeId].name[languageKey] = item.name;
    value[item.classTypeId].description[languageKey] = item.description;
  }
  return value;
};

export const getClassByID = async ({ signal, apiClient, classID, memberId }) => {
  const response = await apiClient.get(
    `/classes/${classID}?$expand=classType, instructor, 
    bookings($filter=memberId eq ${memberId} and isDeleted eq false and isCanceled eq false)`,
    { signal },
  );

  return response.status === 200 ? response.data : null;
};

export const getTrainerByID = async ({ signal, apiClient, trainerID }) => {
  const response = await apiClient.get(`/instructors/${trainerID}`, { signal });
  return response.status === 200 ? response.data : null;
};

export const getClassBookings = async ({ signal, apiClient, memberId, today }) => {
  const response = await apiClient.get(
    `/class-bookings?$filter=memberId eq ${memberId} and 
    isDeleted eq false and hasAttended eq false and 
    class/startDate ge ${today} and class/endDate le ${today}&
    $expand=class($expand=classType,instructor)`,
    { signal },
  );

  return response.data.value;
};

export const bookClass = async ({ apiClient, signal, classId, memberId }) => {
  await apiClient.post(`/book-class`, { memberId, classId }, { signal });
};
