import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Loading from "../../components/Loading";
import Wrapper from "../../components/Login/Wrapper";
import { useBranding } from "../../hooks";
import { useAuth } from "../../utils/AuthProvider";

const LoginPage = () => {
  const branding = useBranding();
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) navigate("/", { replace: true });
  }, [auth, navigate]);

  return auth.isAuthenticated === false && branding ? (
    <Box
      sx={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${branding.assets.loginBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Wrapper>
        <Outlet />
      </Wrapper>
    </Box>
  ) : (
    <Loading />
  );
};
export default LoginPage;
