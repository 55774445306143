import React from "react";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import propTypes from "prop-types";

const DirectionArrow = ({ fontSize, color, reverse }) => {
  return reverse ? (
    <KeyboardArrowRight fontSize={fontSize} color={color} />
  ) : (
    <KeyboardArrowLeft fontSize={fontSize} color={color} />
  );
};

DirectionArrow.propTypes = {
  fontSize: propTypes.oneOf(["small", "medium", "large"]),
  color: propTypes.string,
  reverse: propTypes.bool,
};

export default DirectionArrow;
