import React from "react";
import { Controller } from "react-hook-form";

import { Autocomplete, TextField } from "@mui/material";
import propTypes from "prop-types";

const FormAutoComplete = ({
  name,
  options,
  control,
  disabled,
  rules,
  fullWidth,
  sx,
  size = "medium",
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState, formState }) => {
        const selectedOption = options.find((option) => option.name === value);

        return (
          <Autocomplete
            disablePortal
            disabled={disabled}
            options={options}
            value={selectedOption ?? ""}
            onChange={(e, newValue) => onChange(newValue?.name ? newValue.name : "")}
            sx={sx}
            size={size}
            fullWidth={fullWidth || false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        );
      }}
    />
  );
};

export default FormAutoComplete;

FormAutoComplete.propTypes = {
  name: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  control: propTypes.object.isRequired,
  disabled: propTypes.bool,
  rules: propTypes.object,
  InputProps: propTypes.object,
  size: propTypes.oneOf(["small", "medium"]),
  fullWidth: propTypes.bool,
  sx: propTypes.object,
};
