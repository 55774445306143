export const getMemberProfile = async ({ apiClient, signal, memberId }) => {
  const response = await apiClient.get(`/members/${memberId}`, {
    signal,
    params: {
      $expand:
        "contracts($filter=status ne 'Ended' and isDeleted eq false;$expand=paymentPlan),paymentSources,photos($filter=isDeleted eq false),homeClub,address",
    },
  });

  return response.status === 200 ? response.data : null;
};

export const updateMemberProfile = async ({ apiClient, data }) => {
  await apiClient.post("/member/update", data);
};

export const changeAccountPassword = async ({ apiClient, data }) => {
  await apiClient.post("/members/change-password", data);
};

export const getTransactionHistory = async ({ apiClient, signal, memberId }) => {
  const response = await apiClient.get(
    `/transaction-payments?$filter=memberId eq ${memberId} and isDeleted eq false&$expand=transaction`,
    {
      signal,
    },
  );
  const result = response.status === 200 ? response.data.value.reverse() : [];

  return result;
};

export const cancelSubscription = async ({ apiClient, contractId }) => {
  const response = await apiClient.post(`/contract-cancel`, {
    contractId: contractId,
  });

  return response.data;
};

export const getStates = async ({ apiClient, signal }) => {
  const response = await apiClient.get(`/states?$filter=countryId eq 203`, {
    signal,
  });

  const result = response.status === 200 ? response.data.value : [];

  return result;
};

export const getCities = async ({ apiClient, signal }) => {
  const response = await apiClient.get(`/cities?$filter=countryId eq 203`, {
    signal,
  });

  const result = response.status === 200 ? response.data.value : [];

  return result;
};

export const getContractCharges = async ({ apiClient, signal, memberId }) => {
  const response = await apiClient.get(
    `/contract-charges?$filter=memberId eq ${memberId}  and isDeleted eq false and leftToPay gt 0 and dueDate le ${new Date().toISOString()}&$expand=contract`,
    {
      signal,
    },
  );

  const result = response.status === 200 ? response.data.value : [];

  return result;
};

export const getMemberProfilePhoto = async ({ apiClient, signal, fileId }) => {
  const response = await apiClient.get(`/files/download-url?fileId=${fileId}`, {
    signal,
  });

  return response.status === 200 ? response.data.fileDownloadUrl : null;
};

const deleteMemberProfilePhoto = async ({ apiClient, photos }) => {
  try {
    // delete all profile picture sizes, (note photos fetched are already filtered to be of type ProfilePicture)
    const deletionPromises = photos.map((photo) =>
      apiClient.post("files/delete", { fileId: photo.fileId }),
    );

    await Promise.all(deletionPromises);
    return { statusText: "Ok" };
  } catch (error) {
    console.log(error.message);
    return { statusText: "error", message: "deletePhotoError" };
  }
};

const uploadProfilePhoto = async ({ apiClient, file }) => {
  try {
    const formdata = new FormData();
    formdata.append("file", file);

    const response = await apiClient.post("/files/upload", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.status === 200
      ? { statusText: "Ok" }
      : { statusText: "error", message: "uploadPhotoError" };
  } catch (error) {
    console.log(error.message);
    return { statusText: "error", message: "uploadPhotoError" };
  }
};

export const updateProfilePhoto = async ({ apiClient, userPhotos, newImageFile, uploadPhoto }) => {
  if (userPhotos?.length) {
    const deleteResponse = await deleteMemberProfilePhoto({ apiClient, photos: userPhotos });
    if (deleteResponse.statusText !== "Ok") throw new Error(deleteResponse.message);
  }

  if (uploadPhoto && newImageFile) {
    const uploadResponse = await uploadProfilePhoto({
      apiClient,
      file: newImageFile,
    });

    if (uploadResponse.statusText !== "Ok") throw new Error(uploadResponse.message);
  }
};
