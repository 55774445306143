import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import propTypes from "prop-types";

const Wrapper = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "15px 20px",
        paddingTop: "30px",
        width: isMobile ? "90vw" : "600px",
        gap: "10px",
        "& > *": {
          width: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
};

Wrapper.propTypes = {
  children: propTypes.node.isRequired,
};
export default Wrapper;
