import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useOutletContext, useSearchParams } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Typography } from "@mui/material";
import { format, isBefore, isValid } from "date-fns";

import Calendar from "../../components/Calendar/Calendar";
import ClassCard from "../../components/Cards/ClassCard";
import FormInput from "../../components/Form/FormInput";
import PageHeader from "../../components/PageHeader";
import ClassCardSkeleton from "../../components/Skeletons/ClassCardSkeleton";
import { useAvailableClasses } from "../../hooks";

const pageSize = 10;

const Classes = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, apiClient, dateFnsLocale } = useOutletContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const [classes, setClasses] = useState([]);
  const [fetchLimit, setFetchLimit] = useState(pageSize);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [initialDate, setInitialDate] = useState(null);
  const {
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      filter: "",
    },
  });
  const [startDate, updateStartDate] = useState("");

  useEffect(() => {
    // Get "date" from searchParams on every render
    const dateParam = searchParams.get("date");

    // Only update startDate if the dateParam exists and has changed
    if (dateParam && dateParam !== startDate) {
      updateStartDate(dateParam);
    }
  }, [searchParams, startDate]);
  const filterValue = watch("filter");

  const { data, isPending, isError } = useAvailableClasses({
    apiClient,
    startDate,
    dateFnsLocale,
    memberId: user?.id,
    clubId: user?.homeClubId,
  });

  useEffect(() => {
    if (data.length) setClasses(data);
  }, [data]);

  const isValidDate = useCallback(
    (dateParam) => {
      const date = new Date(dateParam);
      date.setHours(0, 0, 0, 0);
      if (isValid(date) && dateParam !== selectedDate) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return !isBefore(date, today);
      }

      return false;
    },
    [selectedDate],
  );

  useEffect(() => {
    if (searchParams.has("date") && isInitialRender) {
      const dateParam = searchParams.get("date");

      if (isValidDate(dateParam)) {
        const formattedDate = format(new Date(dateParam), "yyyy-MM-dd");
        setIsInitialRender(false);
        setInitialDate(formattedDate);
      }
    }
  }, [isInitialRender, isValidDate, searchParams]);

  const pickDate = useCallback(
    (date) => {
      setSelectedDate(date);
      setSearchParams({ date: date });
      setClasses([]);
      setFetchLimit(pageSize);
    },
    [setSearchParams],
  );

  const filteredClasses = useMemo(() => {
    if (!isDirty) return classes;

    const isMatchingFilter = (item, filter) => {
      // class name check
      if (item.title.toLowerCase().includes(filter)) return true;

      // class trainer check
      if (item.details[0].value.toLowerCase().includes(filter)) return true;
    };

    return classes.filter((item) => {
      return isMatchingFilter(item, filterValue.toLowerCase());
    });
  }, [classes, filterValue, isDirty]);

  const fetchMoreClasses = () => setFetchLimit((state) => (state += pageSize));

  return (
    <Box sx={{ overflow: "hidden" }}>
      <PageHeader title={t("classes")} subtitle={user?.club?.name || ""} />
      <FormInput
        name="filter"
        control={control}
        label={t("classesModule.searchClasses")}
        type="text"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: isDirty && (
            <CancelIcon
              color="action"
              onClick={() => reset()}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            />
          ),
        }}
        sx={{
          "& .MuiInputBase-input": {
            padding: (theme) => theme.spacing(2, 0),
          },
        }}
      />
      <Calendar pickDate={pickDate} initialDate={initialDate} />
      {isError ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {t("somethingWentWrong")}
          </Typography>
        </Box>
      ) : (
        <>
          {filteredClasses.length === 0 && isPending && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <ClassCardSkeleton />
              <ClassCardSkeleton />
            </Box>
          )}
          {filteredClasses.length === 0 && !isPending && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                sx={{ color: (theme) => theme.palette.grey[400] }}
              >
                {t("classesModule.noClasses")}
              </Typography>
            </Box>
          )}
          {filteredClasses.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {filteredClasses.map((item) => (
                <ClassCard
                  key={item.id}
                  classID={item.id}
                  title={item.title}
                  status={item.status}
                  details={item.details}
                  selectedDate={selectedDate}
                />
              ))}
              {isPending && (
                <>
                  <ClassCardSkeleton />
                  <ClassCardSkeleton />
                </>
              )}
            </Box>
          )}
          {!isPending && fetchLimit < data?.count && (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
              <ExpandCircleDownIcon
                fontSize="large"
                color="primary"
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.dark",
                  },
                }}
                onClick={fetchMoreClasses}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Classes;
