import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import ClassTrainer from "../../../components/ClassTrainer";
import DataComponent from "../../../components/DataComponent";
import PageHeader from "../../../components/PageHeader";
import ClassDetailsSkeleton from "../../../components/Skeletons/ClassDetailsSkeleton";
import ClassTrainerSkeleton from "../../../components/Skeletons/ClassTrainerSkeleton";
import StatusBadge from "../../../components/StatusBadge";
import ThemedButton from "../../../components/ThemedButton";
import { useClassDetails } from "../../../hooks";
import { bookClass } from "../../../services/api";

const ClassDetails = () => {
  const { t } = useTranslation();
  const { classID } = useParams();
  const { state } = useLocation();
  const queryClient = useQueryClient();
  const {
    user,
    apiClient,
    dateFnsLocale,
    viewport: { isMobile },
  } = useOutletContext();

  const { data: details, isPending } = useClassDetails({
    apiClient,
    dateFnsLocale,
    classID,
    memberId: user?.id,
  });

  const memberBooking = useMemo(() => {
    if (details?.bookings) {
      return details.bookings.find((booking) => booking.memberId === user?.id);
    }
    return null;
  }, [details?.bookings, user?.id]);

  const { mutate, isPending: pendingClassBooking } = useMutation({
    mutationFn: bookClass,
    onSuccess: () => {
      toast.success(t("classesModule.classBookedSuccess"));
      queryClient.invalidateQueries(["class", { classID }]);
    },
    onError: (error) => {
      toast.error(t("somethingWentWrong"));
    },
  });

  const handleBookClass = () => {
    mutate({
      apiClient,
      memberId: user?.id,
      classId: classID,
    });
  };

  let pageElements = null;
  let trainerElement = null;

  // trainer rendering logic
  if (isPending) trainerElement = <ClassTrainerSkeleton />;
  if (!isPending && details?.instructor)
    trainerElement = (
      <ClassTrainer
        trainer={details.instructor}
        classID={classID}
        nextLinkState={{ prevRoute: state?.prevRoute || "/classes" }}
      />
    );

  // final page elements rendering logic class details and trainer
  if (isPending) pageElements = <ClassDetailsSkeleton />;

  if (!isPending && details) {
    pageElements = (
      <Box>
        <PageHeader
          title={details.name}
          subtitle={"Malqa Men Branch"}
          prevRoute={state?.prevRoute || "/classes"}
        />
        <Box
          sx={{
            bgcolor: "customColors.bg1",
            border: "1px solid",
            borderColor: "customColors.border",
            borderRadius: 1,
            padding: (theme) => theme.spacing(1, 2),
          }}
        >
          <Box
            component="img"
            src={details.image}
            alt="Class Logo"
            sx={{
              width: "100%",
              maxHeight: { xs: "6rem", sm: "8rem", md: "10rem", xl: "12rem" },
              objectFit: "cover",
              borderRadius: 0.5,
            }}
            width="100%"
            maxHeight={{ xs: "6rem", sm: "8rem", md: "10rem", xl: "12rem" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: (theme) => theme.spacing(1, 0),
            }}
          >
            <Typography variant="h6" component="h2">
              {details.name}
            </Typography>
            <StatusBadge status={details.status} />
          </Box>
          <DataComponent title={details.description} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <DataComponent
              title={t("classesModule.grpSize")}
              value={{ text: details.groupSize, isLink: false, target: null }}
              sx={{ flex: "1" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flex: "1",
              }}
            >
              <DataComponent
                title={t("classesModule.time")}
                value={{ text: details.time, isLink: false, target: null }}
              />
              <DataComponent
                title={t("classesModule.duration")}
                value={{ text: details.duration, isLink: false, target: null }}
              />
            </Box>
          </Box>
          {trainerElement}
          {details?.isReservationRequired && memberBooking === undefined && (
            <ThemedButton
              variant="contained"
              sx={{ marginTop: 4 }}
              fullWidth
              onClick={handleBookClass}
              loading={pendingClassBooking}
              disabled={details.status === "full" || pendingClassBooking}
            >
              {t("classesModule.bookClass")}
            </ThemedButton>
          )}

          {memberBooking && (
            <Typography component="h2" color="primary" sx={{ textAlign: "center" }}>
              {t("classesModule.youBookedThisClass")}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return <Box>{pageElements}</Box>;
};

export default ClassDetails;
