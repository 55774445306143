import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Modal, Typography } from "@mui/material";
import propTypes from "prop-types";

import ThemedButton from "../ThemedButton";
import ModalCloseIcon from "./ModalCloseIcon";

const ConfirmationModal = ({ title, description, isOpen, isPending, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="confirm-cancellation-modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -20%)",
            md: "translate(-40%, -50%)",
            lg: "translate(-45%, -50%)",
          },
          width: { xs: "90%", md: "50%" },
          backgroundColor: "customColors.modalBg",
          border: "2px solid",
          borderRadius: 1.5,
          borderColor: "customColors.border",
          boxShadow: 24,
          p: 2,
        }}
      >
        <ModalCloseIcon onClose={onClose} />
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
        <Typography variant="body1" component="p" sx={{ marginTop: 1 }}>
          {description}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: 3 }}>
          <ThemedButton
            variant="secondary"
            size="small"
            onClick={onClose}
            disabled={isPending}
            sx={{ marginInlineEnd: 1 }}
          >
            {t("cancel")}
          </ThemedButton>
          <ThemedButton variant="contained" size="small" onClick={onConfirm} loading={isPending}>
            {t("confirm")}
          </ThemedButton>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  isPending: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
};

export default ConfirmationModal;
