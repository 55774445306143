import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { toggleLanguage } from "../../services/internationalization/i18n";

const LanguageChangeBtn = () => {
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      disableRipple
      onClick={toggleLanguage}
      sx={{
        color: "#333",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "600",
        textDecorationLine: "underline !important",
        "&:hover, &:active": {
          backgroundColor: "transparent",
        },
      }}
    >
      {t("lang")}
    </Button>
  );
};

export default LanguageChangeBtn;
