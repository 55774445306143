import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import propTypes from "prop-types";

const NavLinkWrapper = forwardRef((props, ref) => {
  const { muiClassName, ...otherProps } = props;
  return <NavLink ref={ref} className={muiClassName} {...otherProps} />;
});

NavLinkWrapper.displayName = "NavLinkWrapper";
NavLinkWrapper.propTypes = {
  muiClassName: propTypes.func,
  to: propTypes.string,
};

export default NavLinkWrapper;
