import React from "react";

import { Skeleton } from "@mui/material";

import PackageCardWrapper from "../Wrappers/PackageCardWrapper";

const PackageCardSkeleton = () => {
  return (
    <PackageCardWrapper sx={{ backgroundColor: (theme) => theme.palette.action.hover }}>
      <Skeleton variant="text" width="80%" animation="wave" />
      <Skeleton variant="text" width="60%" animation="wave" />
      <Skeleton variant="text" width="30%" animation="wave" sx={{ marginTop: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={30} animation="wave" />
    </PackageCardWrapper>
  );
};

export default PackageCardSkeleton;
