import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, useTheme } from "@mui/material";
import propTypes from "prop-types";

const Price = ({ value }) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
      }}
    >
      <Typography
        fontWeight="fontWeightBold"
        sx={{
          color: theme.palette.primary.main,
          fontSize: "2.2rem",
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: "1.375rem",
          fontWeight: "600",
        }}
      >
        {i18n.language === "en" ? "SAR" : "ر.س."}
      </Typography>
    </Box>
  );
};

Price.propTypes = {
  value: propTypes.number.isRequired,
};

export default Price;
