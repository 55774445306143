import React, { createContext, useContext, useEffect, useReducer } from "react";
import { redirect } from "react-router-dom";

import axios from "axios";
import propTypes from "prop-types";

import { useBranding } from "../hooks";
import { useHttpRequest } from "../services/api";

const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload };
    case "SET_ERROR":
      return { ...state, isError: action.payload.isError, error: action.payload.error };
    case "SET_TOKEN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("memberId", action.payload.memberId);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        memberId: action.payload.memberId,
      };
    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("memberId");
      return { ...state, token: null, isAuthenticated: false };
    default:
      return state;
  }
};

const initialState = {
  token: null,
  isAuthenticated: null,
  isLoading: false,
  isError: false,
  error: {},
  memberId: null,
};

export const AuthProvider = ({ children }) => {
  const branding = useBranding();
  const [auth, dispatch] = useReducer(authReducer, initialState);
  const { axiosInstance, clearAxiosInterceptors, setAxiosInterceptors } = useHttpRequest();

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    const savedMemberId = localStorage.getItem("memberId");

    if (savedToken && savedMemberId) {
      const memberId = JSON.parse(savedMemberId);
      dispatch({
        type: "SET_TOKEN",
        payload: {
          token: savedToken,
          memberId: memberId,
        },
      });
    } else {
      dispatch({ type: "SET_AUTHENTICATED", payload: false });
    }
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) setAxiosInterceptors(auth.token);
  }, [auth.isAuthenticated, auth.token, setAxiosInterceptors]);

  const login = async (authData) => {
    if (!branding?.apiUrl) return;

    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_ERROR", payload: { isError: false, error: {} } });
    try {
      const response = await axios.post(`${branding.apiUrl}/auth/login`, {
        login: authData.email,
        password: authData.password,
      });

      dispatch({
        type: "SET_TOKEN",
        payload: {
          token: response.data.auth_token,
          memberId: response.data.auth.memberId,
          // memberProfile: response.data.member,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_ERROR",
        payload: {
          isError: true,
          error: {
            statusText: "Bad Request",
            title: "login.invalid_cred.title",
            message: "login.invalid_cred.message",
          },
        },
      });
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const logout = (queryClient) => {
    dispatch({ type: "LOGOUT", payload: null });
    clearAxiosInterceptors();
    queryClient.clear();
    redirect("/login");
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, axiosInstance }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
