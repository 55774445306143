import React from "react";
import { Controller } from "react-hook-form";

import { TextField } from "@mui/material";
import propTypes from "prop-types";

const FormInput = ({
  name,
  control,
  label,
  type,
  disabled,
  rules,
  InputProps,
  size = "medium",
  fullWidth,
  sx,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, formState }) => (
        <TextField
          sx={sx}
          {...field}
          type={type}
          size={size}
          disabled={disabled || formState.isSubmitting}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : null}
          placeholder={label}
          InputProps={InputProps || null}
          fullWidth={fullWidth || false}
        />
      )}
    />
  );
};

FormInput.propTypes = {
  name: propTypes.string.isRequired,
  control: propTypes.object.isRequired,
  label: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  disabled: propTypes.bool,
  rules: propTypes.object,
  InputProps: propTypes.object,
  size: propTypes.oneOf(["small", "medium"]),
  fullWidth: propTypes.bool,
  sx: propTypes.object,
};

export default FormInput;
