import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import googleTagManager from "@analytics/google-tag-manager";
import { createTheme } from "@mui/material/styles";
import Analytics from "analytics";

import { generalThemeConfig } from "../theme/theme";

export const useBrandedTheme = () => {
  const { i18n } = useTranslation();
  const branding = useBranding();

  const fontFamily = i18n.language === "ar" ? "Changa, sans-serif" : "Poppins, sans-serif";
  const theme = createTheme({
    ...generalThemeConfig,
    palette: branding ? branding.palette : generalThemeConfig.palette,
    typography: {
      ...generalThemeConfig.typography,
      fontFamily: fontFamily,
    },
  });

  return theme;
};

export const useBranding = () => {
  useEffect(() => {
    const brandingConfig = window.brandingConfig;
    if (brandingConfig) {
      document.title = brandingConfig.appTitle || "Members";
      const favicon = document.getElementById("favicon");
      if (favicon) {
        favicon.setAttribute("href", brandingConfig.assets.favicon);
      }
    }
  }, []);

  return window.brandingConfig;
};

export const useAnalytics = () => {
  const branding = useBranding();

  if (!branding) return;

  const analytics = Analytics({
    app: branding.appTitle,
    plugins: [
      googleTagManager({
        containerId: branding.analyticsId,
      }),
    ],
  });

  return analytics.page();
};

export {
  useSetDocumentDir,
  useDir,
  useProductTranslations,
  usePaymentPlanTranslations,
  useClassesTranslations,
} from "./language";
export {
  useMemberProfile,
  useTransactionHistory,
  useMemberProducts,
  useMemberContracts,
  useRenewalPlans,
  useFirstTimePlans,
  usePtProductBundles,
  useAvailableClasses,
  useClassDetails,
  useTrainerDetails,
  useSaudiStates,
  useSaudiCities,
  usePendingPayments,
  useMemberProfilePhoto,
  useClassBookings,
  useContractPayments,
} from "./DataFetching";
