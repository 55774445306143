import React from "react";

import { Box, Skeleton } from "@mui/material";

const ClassTrainerSkeleton = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "start", marginTop: 2 }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="45px"
          height="45px"
          sx={{ marginInlineEnd: 1, borderRadius: 1 }}
        />
        <Box>
          <Skeleton animation="wave" variant="text" width="3rem" />
          <Skeleton animation="wave" variant="text" width="6rem" />
        </Box>
      </Box>
      <Skeleton animation="wave" variant="text" width="80%" height={20} />
      <Skeleton animation="wave" variant="text" width="40%" height={20} />
      <Skeleton animation="wave" variant="text" width="20%" height={20} />
    </>
  );
};

export default ClassTrainerSkeleton;
