export class TabbyTamaraPayment {
  constructor({ config, dispatch, endpoint, apiClient }) {
    this.config = config;
    this.dispatch = dispatch;
    this.endpoint = endpoint;
    this.apiClient = apiClient;
  }

  async pay() {
    try {
      this.dispatch({ type: "SET_LOADING", payload: true });

      const transactionPayload = {
        paymentPlanId: this.config.itemDetails.id,
        memberId: this.config.memberDetails.id,
        lang: this.config.memberDetails.lang,
        startDate: this.config.startDate,
        successUrl: `${this.config.publicUrl}/order-complete?status=Ok`,
        failureUrl: `${this.config.publicUrl}/order-complete?status=Error`,
        cancelUrl: `${this.config.publicUrl}/order-complete?status=cancelled`,
      };

      const result = await this.apiClient.post(this.endpoint, transactionPayload);

      if (result.status === 200) window.location.href = result.data.url;
      else {
        console.log("error block");
        console.log(result);
        this.dispatch({ type: "SET_ERROR", payload: "somethingWentWrong" });
        this.dispatch({ type: "SET_LOADING", payload: false });
      }
    } catch (error) {
      console.log(error);
      this.dispatch({ type: "SET_ERROR", payload: "somethingWentWrong" });
      this.dispatch({ type: "SET_LOADING", payload: false });
    }
  }
}
