import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import propTypes from "prop-types";

import { useDir } from "../../hooks";

const ModalCloseIcon = ({ onClose }) => {
  const dir = useDir();

  return (
    <CloseIcon
      fontSize="large"
      onClick={onClose}
      sx={{
        position: "absolute",
        top: "10px",
        cursor: "pointer",
        ...(dir === "ltr" ? { right: "10px" } : { left: "10px" }),
      }}
    />
  );
};

ModalCloseIcon.propTypes = {
  onClose: propTypes.func.isRequired,
};

export default ModalCloseIcon;
