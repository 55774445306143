import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box, InputLabel, TextField, Typography } from "@mui/material";
import propTypes from "prop-types";

import { useBranding } from "../../hooks";

const FormImageInput = forwardRef(function FormImageInput(
  { name, control, disabled, rules, onFileChange },
  ref,
) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { name, value }, fieldState, formState }) => (
        <InputLabel
          sx={{ width: "130px", height: "130px", margin: (theme) => theme.spacing(1, 0) }}
        >
          <TextField
            name={name}
            onChange={onFileChange}
            sx={{ display: "none" }}
            type="file"
            inputProps={{ accept: "image/png, image/jpeg", ref: ref }}
            disabled={disabled || formState.isSubmitting || value?.length > 0}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
          />
          {value ? <ImagePreview image={value} /> : <ImageInputMask />}
        </InputLabel>
      )}
    />
  );
});

FormImageInput.displayName = "FormImageInput";

FormImageInput.propTypes = {
  name: propTypes.string.isRequired,
  control: propTypes.object.isRequired,
  disabled: propTypes.bool,
  rules: propTypes.object,
  onFileChange: propTypes.func.isRequired,
};

export default FormImageInput;

const ImagePreview = ({ image }) => {
  return (
    <Box
      style={{
        objectFit: "cover",
        objectPosition: "50% 10%",
        width: "130px",
        height: "130px",
      }}
      component="img"
      src={image}
      alt="Uploaded image"
    />
  );
};

ImagePreview.propTypes = {
  image: propTypes.string.isRequired,
};

const ImageInputMask = () => {
  const branding = useBranding();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "customColors.fileBtnBg",
        border: "2px dashed",
        borderColor: "customColors.fileBtnBorder",
        borderRadius: 1,
        height: "130px",
        width: "130px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        cursor: "pointer",
      }}
    >
      {branding && <Box component="img" src={branding.assets?.addPicture} alt="Add profile" />}
      <Box sx={{ maxWidth: "70%", textWrap: "wrap", whiteSpace: "pre-wrap", textAlign: "center" }}>
        <Typography
          variant="subtitle2"
          component="span"
          sx={{
            fontSize: "0.8rem",
            flex: 0,
          }}
        >
          {t("settingsModule.uploadPhoto")}
        </Typography>
      </Box>
    </Box>
  );
};
