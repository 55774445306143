import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Box } from "@mui/material";

import PageHeader from "../../components/PageHeader";
import PendingPaymentAlert from "../../components/PendingPaymentAlert";
import Appointments from "./Appointments/Appointments";
import CurrentPackage from "./CurrentPackage/CurrentPackage";
import LatestPayments from "./LatestPayments/LatestPayments";
import PersonalTraining from "./PersonalTraining/PersonalTraining";

const Dashboard = () => {
  const { t } = useTranslation();
  const { userHasPendingPayments } = useOutletContext();

  return (
    <Box>
      {userHasPendingPayments && <PendingPaymentAlert />}
      <PageHeader title={t("dashboard")} />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5, paddingTop: 2 }}>
        <CurrentPackage />
        <Appointments />
        <PersonalTraining />
      </Box>
      <LatestPayments />
    </Box>
  );
};

export default Dashboard;
