import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  getclassTypeTranslations,
  getpaymentPlanTranslations,
  getproductTranslations,
} from "../services/api/";

export const useSetDocumentDir = () => {
  const { i18n } = useTranslation();

  /**
   * Sets the document direction based on the current language.
   */
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);
};

export const useDir = () => {
  const { i18n } = useTranslation();

  return i18n.dir();
};

export const useProductTranslations = ({ apiClient }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["producTranslations"],
    queryFn: ({ signal }) => getproductTranslations({ signal, apiClient }),
    staleTime: Infinity,
  });

  return { data: data ?? {}, isLoading };
};

export const usePaymentPlanTranslations = ({ apiClient }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["paymentPlanTranslations"],
    queryFn: ({ signal }) => getpaymentPlanTranslations({ signal, apiClient }),
    staleTime: Infinity,
  });

  return { data: data ?? {}, isLoading };
};

export const useClassesTranslations = ({ apiClient }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["classesTranslations"],
    queryFn: ({ signal }) =>
      getclassTypeTranslations({
        signal,
        apiClient,
      }),
    staleTime: 5 * 60 * 1000,
  });

  return { data: data ?? {}, isLoading };
};
