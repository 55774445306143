import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

const Discount = ({ value }) => {
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
      }}
    >
      <Typography
        fontWeight="fontWeightRegular"
        sx={{
          textDecoration: "line-through",
          fontSize: "1.5rem",
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: "0.8rem",
          textDecoration: "line-through",
          fontWeight: "600",
        }}
      >
        {i18n.language === "en" ? "SAR" : "ر.س."}
      </Typography>
    </Box>
  );
};

Discount.propTypes = {
  value: propTypes.number.isRequired,
};

export default Discount;
