import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { addDays, eachDayOfInterval, format } from "date-fns";
import { arEG, enGB } from "date-fns/locale";
import propTypes from "prop-types";

import DayCard from "./DayCard";
import StepButton from "./StepButton";

const Calendar = ({ pickDate, initialDate }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("lg")) && !isMobile;
  const { t, i18n } = useTranslation();
  const dateFnsLocale = i18n.language === "ar" ? arEG : enGB;

  const [step, setStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);

  /** lifts the selected date state up to the parent */
  useEffect(() => {
    if (pickDate) pickDate(format(selectedDate, "yyyy-MM-dd"));
  }, [pickDate, selectedDate]);

  const dayCardsCount = useMemo(() => (isMobile ? 3 : isTablet ? 7 : 10), [isMobile, isTablet]);
  const daysRange = useMemo(() => {
    const startDate = addDays(new Date(), step * dayCardsCount);
    setSelectedDate(startDate);

    const endDate = addDays(startDate, dayCardsCount - 1);
    const range = eachDayOfInterval({ start: startDate, end: endDate });

    return range;
  }, [dayCardsCount, step]);

  useEffect(() => {
    let month = format(daysRange[0], i18n.language === "ar" ? "MMMM y" : "MMM y", {
      locale: dateFnsLocale,
    });
    if (daysRange[0].getMonth() !== daysRange[daysRange.length - 1].getMonth())
      month += ` - ${format(daysRange[daysRange.length - 1], i18n.language === "ar" ? "MMMM y" : "MMM y", { locale: dateFnsLocale })}`;

    setCurrentMonth(month);
  }, [dateFnsLocale, daysRange, i18n.language]);

  useEffect(() => {
    if (initialDate && isInitialRender) {
      const index = daysRange.findIndex((date) => format(date, "yyyy-MM-dd") === initialDate);
      if (index > -1) {
        setSelectedDate(daysRange[index]);
        setActiveIndex(index);
        setIsInitialRender(false);
      } else getNextDaysRange();
    }
  }, [daysRange, initialDate, isInitialRender]);

  const selectDate = (index) => {
    setSelectedDate(daysRange[index]);
    setActiveIndex(index);
  };

  const getNextDaysRange = () => {
    setStep((state) => state + 1);
    setActiveIndex(0);
  };
  const getPreviousDaysRange = () => {
    setStep((state) => (state > 0 ? state - 1 : state));
    setActiveIndex(0);
  };

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2, 0) }}>
      <Typography variant="subtitle2" component="h4">
        {t("calendar.selectDate")}
      </Typography>
      <Typography variant="h4" fontWeight="fontWeightBold" component="h3">
        {currentMonth}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: (theme) => theme.spacing(1),
          justifyContent: "space-between",
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        {step > 0 && <StepButton onClick={getPreviousDaysRange} reverse={i18n.language === "ar"} />}
        {daysRange.map((date, index) => (
          <DayCard
            key={date.toString()}
            day={format(date, "E", { locale: dateFnsLocale })}
            date={format(date, "d", { locale: dateFnsLocale })}
            isActive={index === activeIndex}
            onClick={() => selectDate(index)}
          />
        ))}
        <StepButton onClick={getNextDaysRange} reverse={i18n.language === "en"} />
      </Box>
    </Box>
  );
};

Calendar.propTypes = {
  pickDate: propTypes.func.isRequired,
  initialDate: propTypes.string,
};

export default Calendar;
