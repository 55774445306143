import React from "react";
import { Link as routerLink } from "react-router-dom";

import { Box, Link, Typography, useTheme } from "@mui/material";
import propTypes from "prop-types";

const DataComponent = ({ title, value, sx }) => {
  const theme = useTheme();

  const styles = {
    display: "flex",
    borderInlineStart: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0, 1, 0, 1),
    marginBottom: 2,
    flexDirection: "column",
    justifyContent: "center",
    ...sx,
  };

  let subText = null;

  if (value) {
    if (value.isLink) {
      subText = (
        <Link component={routerLink} to={value.target} state={value.linkState}>
          <Typography variant="link" fontWeight={600}>
            {value.text}
          </Typography>
        </Link>
      );
    } else
      subText = (
        <Typography variant="subtitle1" fontWeight="fontWeightBold">
          {value.text}
        </Typography>
      );
  }
  return (
    <Box sx={styles}>
      <Typography variant="subtitle2" fontWeight="fontWeightMedium">
        {title}
      </Typography>

      {subText}
    </Box>
  );
};

DataComponent.propTypes = {
  title: propTypes.string,
  value: propTypes.shape({
    text: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
    isLink: propTypes.bool.isRequired,
    linkState: propTypes.object,
    target: propTypes.string,
  }),
  sx: propTypes.object,
};

export default DataComponent;
