import React from "react";
import { useTranslation } from "react-i18next";
import { Link as routerLink } from "react-router-dom";

import { Box, Link, Typography } from "@mui/material";
import propTypes from "prop-types";

import DataComponent from "../DataComponent";

const InfoCard = ({ title, subTitle, data, bgColor = "customColors.bg1", fullWidth }) => {
  const { i18n } = useTranslation();

  const styles = {
    border: "1px solid",
    borderColor: "customColors.border",
    borderRadius: 2,
    minHeight: "17rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: bgColor,
    width: fullWidth
      ? "100%"
      : { xs: "100%", md: "calc(100% / 2 - 20px)", lg: "calc(100% / 3 - 20px)" },
    padding: (theme) => theme.spacing(2),
  };

  return (
    <Box sx={styles}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography fontWeight="fontWeightMedium" variant="subtitle1">
          {title[i18n.language]}
        </Typography>
        <Link
          variant="textLink"
          component={routerLink}
          to={subTitle.target}
          style={{ minWidth: "5.5rem", textAlign: "end" }}
        >
          {subTitle?.text}
        </Link>
      </Box>
      <Box>
        <DataComponent title={data[0].title} value={data[0].value} />
        <DataComponent title={data[1].title} value={data[1].value} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DataComponent title={data[2].title} value={data[2].value} />
          {data.length === 4 && <DataComponent title={data[3].title} value={data[3].value} />}
        </Box>
      </Box>
    </Box>
  );
};

InfoCard.propTypes = {
  title: propTypes.object.isRequired,
  subTitle: propTypes.shape({
    text: propTypes.string.isRequired,
    target: propTypes.string.isRequired,
  }),
  data: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      value: propTypes.shape({
        isLink: propTypes.bool.isRequired,
        text: propTypes.string.isRequired,
        target: propTypes.string,
      }),
    }),
  ).isRequired,
  bgColor: propTypes.string,
  fullWidth: propTypes.bool,
};

export default InfoCard;
