import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { differenceInYears, format } from "date-fns";

import FormAutoComplete from "../../../components/Form/FormAutoComplete";
import FormDatePicker from "../../../components/Form/FormDatePicker";
import FormImageInput from "../../../components/Form/FormImageInput";
import FormInput from "../../../components/Form/FormInput";
import FormSelectInput from "../../../components/Form/FormSelectInput";
import UserProfileSkeleton from "../../../components/Skeletons/UserProfileSkeleton";
import ThemedButton from "../../../components/ThemedButton";
import { useMemberProfilePhoto, useSaudiCities, useSaudiStates } from "../../../hooks";
import { updateMemberProfile, updateProfilePhoto } from "../../../services/api";

const ProfileForm = () => {
  const { t, i18n } = useTranslation();
  const { user, apiClient } = useOutletContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const queryClient = useQueryClient();
  const imageInputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [uploadNewImage, setUploadNewImage] = useState(false);
  const [deleteExistingPhoto, setDeleteExistingPhoto] = useState(false);

  const { data: statesData } = useSaudiStates({ apiClient });

  const { data: citiesData } = useSaudiCities({ apiClient });

  const {
    data: profilePhoto,
    isPending: isPhotoPending,
    refetch: refetchPhoto,
  } = useMemberProfilePhoto({
    apiClient,
    fileId: user?.profilePhotoId,
  });

  const { mutate: mutateMemberProfile, isPending: pendingProfileUpdate } = useMutation({
    mutationFn: updateMemberProfile,
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
      toast.success(t("settingsModule.profileUpdated"));
    },
    onError: (error) => {
      toast.error(t("somethingWentWrong"));
    },
  });

  const { mutate: mutateProfilePicture, isPending: pendingPictureUpdate } = useMutation({
    mutationFn: updateProfilePhoto,
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
      setUploadNewImage(false);
      setDeleteExistingPhoto(false);
      reset({ image: profilePhoto });
      toast.success(t("settingsModule.profilePictureUpdated"));
    },
    onError: (error) => {
      console.log(error);
      toast.error(t(`settingsModule.${error.message}`));
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      image: profilePhoto || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      name: user?.name || "",
      email: user?.email || "",
      dob: user?.dateOfBirth ? format(user.dateOfBirth, "yyyy-MM-dd") : "",
      mobile_no: user?.mobile || "",
      sex: user?.gender || "",
      personalId: user?.nationalID || "",
      countrySymbol: user?.address.countrySymbol || "",
      state: user?.address.state || "",
      city: user?.address.city || "",
      street: user?.address.street || "",
      postalCode: user?.address.postalCode || "",
    },
  });

  const photoWatch = watch("image");

  useEffect(() => {
    if (user && reset) {
      reset({
        image: profilePhoto || "",
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.name,
        email: user.email,
        dob: format(user.dateOfBirth, "yyyy-MM-dd"),
        mobile_no: user.mobile,
        sex: user.gender,
        personalId: user.nationalID,
        countrySymbol: user.address.countrySymbol,
        state: user.address.state,
        city: user.address.city,
        street: user.address.street,
        postalCode: user.address.postalCode,
      });
    }
  }, [profilePhoto, reset, user]);

  useEffect(() => {
    if (user?.profilePhotoId) {
      refetchPhoto();
      if (profilePhoto) setValue("image", profilePhoto);
    } else reset({ image: "" });
  }, [profilePhoto, refetchPhoto, reset, setValue, user?.profilePhotoId]);

  useEffect(() => {
    if (!isPhotoPending && profilePhoto) reset({ image: profilePhoto });
  }, [isPhotoPending, profilePhoto, reset]);

  const stateOptions = useMemo(() => {
    if (!statesData?.length) return [];

    return statesData.map((state) => ({ ...state, label: state.name }));
  }, [statesData]);

  const cityOptions = useMemo(() => {
    if (!citiesData?.length) return [];

    return citiesData.map((state) => ({ ...state, label: state.name }));
  }, [citiesData]);

  const handleFileChange = (event) => {
    setUploadNewImage(true);

    const file = event.target.files[0];

    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = (e) => {
        const data = e.target.result;
        setValue("image", data);
      };
    }
  };

  const handleRemoveImage = () => {
    if (user?.profilePhotoId) setDeleteExistingPhoto(true);
    setUploadNewImage(false);
    setValue("image", "");
    setImageFile(null);
    imageInputRef.current.value = "";
  };

  const onSubmit = async (data) => {
    const selectedState = statesData.find((state) => state.name === data.state);
    if (!selectedState) return;

    const newFormData = {
      memberId: user.id,
      personalData: {
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: data.dob,
        sex: data.sex,
        phoneNumber: data.mobile_no,
        email: data.email,
        citizenshipCountrySymbol: "SA",
        personalId: data.personalId,
      },
      addressData: {
        country: "Saudi Arabia",
        countrySymbol: data.countrySymbol,
        state: data.state,
        stateId: selectedState.id,
        street: data.street,
        postalCode: data.postalCode,
        cityName: data.city,
      },
    };

    if (uploadNewImage || deleteExistingPhoto) {
      mutateProfilePicture({
        apiClient: apiClient,
        userPhotos: user?.photos || [],
        newImageFile: imageFile,
        uploadPhoto: uploadNewImage,
      });
    }

    if (isDirty) {
      mutateMemberProfile({
        apiClient: apiClient,
        data: newFormData,
      });
    }

    reset(data);
  };

  const validateDateOfBirth = (value) => {
    if (value === "") return true;

    const today = new Date();
    const birthDate = new Date(value);
    const yearsDifference = differenceInYears(today, birthDate);

    if (yearsDifference < 18) return t("settingsModule.ageValidationMsg");
    else return true;
  };

  if (!user || !statesData || !citiesData) {
    return <UserProfileSkeleton isDesktop={isDesktop} isMobile={isMobile} />;
  }

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2, 0),
      }}
    >
      <Typography variant="subtitle1" fontWeight={500}>
        {t("settingsModule.yourProfilePic")}
      </Typography>
      <FormImageInput
        name="image"
        control={control}
        disabled={isPhotoPending}
        rules={{}}
        ref={imageInputRef}
        onFileChange={handleFileChange}
      />
      {photoWatch && (
        <Button
          variant="text"
          startIcon={<CancelIcon />}
          onClick={handleRemoveImage}
          disabled={!user}
        >
          <Typography variant="body1" fontWeight="fontWeightMedium" sx={{ marginRight: 1 }}>
            {t("settingsModule.removeImg")}
          </Typography>
        </Button>
      )}
      <Typography variant="subtitle1" fontWeight={500} sx={{ marginTop: 4 }}>
        {t("settingsModule.personalData")}
      </Typography>
      <Box
        sx={{
          padding: (theme) => theme.spacing(1, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.firstName")}
          </Typography>
          <FormInput
            name="firstName"
            control={control}
            label={t("settingsModule.firstName")}
            type="text"
            fullWidth={isMobile}
            rules={{
              required: t("settingsModule.nameIsRequired"),
              validate: (value) => value.trim().length > 3 || t("settingsModule.nameIsTooShort"),
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.lastName")}
          </Typography>
          <FormInput
            name="lastName"
            control={control}
            label={t("settingsModule.lastName")}
            type="text"
            fullWidth={isMobile}
            rules={{
              required: t("settingsModule.nameIsRequired"),
              validate: (value) => value.trim().length > 3 || t("settingsModule.nameIsTooShort"),
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.email")}
          </Typography>
          <FormInput
            name="email"
            control={control}
            label={t("settingsModule.email")}
            type="email"
            fullWidth={isMobile}
            disabled={true}
            rules={{ required: true }}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.dateOfBirth")}
          </Typography>
          <FormDatePicker
            control={control}
            name="dob"
            rules={{ validate: validateDateOfBirth }}
            sx={{
              width: isMobile ? "100%" : "24rem",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.mobileNumber")}
          </Typography>
          <FormInput
            name="mobile_no"
            control={control}
            label={t("settingsModule.mobileNumber")}
            type="tel"
            fullWidth={isMobile}
            // InputProps={{
            //   startAdornment: <InputAdornment position="start">+966</InputAdornment>,
            // }}
            rules={
              {
                // minLength: { value: 10, message: t("settingsModule.mobileLength") },
                // maxLength: { value: 10, message: t("settingsModule.mobileLength") },
                // pattern: { value: /^05\d*$/, message: t("settingsModule.invalidMobile") },
              }
            }
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
                textAlign: i18n.language === "ar" ? "right" : "left",
              },
            }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.gender")}
          </Typography>
          <FormSelectInput
            name="sex"
            control={control}
            label="gender"
            sx={{ width: isMobile ? "100%" : "24rem" }}
            disabled
            menuItems={["male", "female"]}
          />
        </Box>
      </Box>

      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.nationalID")}
          </Typography>
          <FormInput
            name="personalId"
            control={control}
            label={t("settingsModule.nationalID")}
            type="text"
            fullWidth={isMobile}
            disabled
            rules={{}}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>
      </Box>

      <Typography variant="subtitle1" fontWeight={500} sx={{ marginTop: 4 }}>
        {t("settingsModule.addressData")}
      </Typography>
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.country")}
          </Typography>
          <FormSelectInput
            name="countrySymbol"
            control={control}
            label="Select Country"
            sx={{ width: isMobile ? "100%" : "24rem" }}
            disabled
            menuItems={["SA"]}
          />
        </Box>
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.state")}
          </Typography>
          <FormAutoComplete
            name="state"
            control={control}
            rules={{ required: t("settingsModule.stateIsRequired") }}
            sx={{ width: isMobile ? "100%" : "24rem" }}
            options={stateOptions}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.city")}
          </Typography>
          <FormAutoComplete
            name="city"
            control={control}
            rules={{ required: t("settingsModule.cityIsRequired") }}
            sx={{ width: isMobile ? "100%" : "24rem" }}
            options={cityOptions}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.street")}
          </Typography>
          <FormInput
            name="street"
            control={control}
            label={t("settingsModule.street")}
            type="text"
            fullWidth={isMobile}
            rules={{
              required: t("settingsModule.streetIsRequired"),
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 0),
          display: "flex",
          gap: isDesktop ? 10 : 3,
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={500}>
            {t("settingsModule.postalCode")}
          </Typography>
          <FormInput
            name="postalCode"
            control={control}
            label={t("settingsModule.postalCode")}
            type="text"
            fullWidth={isMobile}
            rules={{ required: t("settingsModule.postalCodeIsRequired") }}
            sx={{
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(2, 3),
                width: isMobile ? "100%" : "21rem",
              },
            }}
          />
        </Box>
      </Box>

      <ThemedButton
        variant="contained"
        disabled={
          pendingProfileUpdate ||
          pendingPictureUpdate ||
          (!isDirty && !uploadNewImage && !deleteExistingPhoto)
        }
        loading={pendingProfileUpdate || pendingPictureUpdate}
        sx={{
          margin: (theme) => theme.spacing(2, 0),
          marginTop: 8,
          padding: (theme) => theme.spacing(2, 0),
          width: isMobile ? "100%" : "24rem",
          fontWeight: "bold",
        }}
        onClick={handleSubmit(onSubmit)}
      >
        {t("saveChanges")}
      </ThemedButton>
    </Box>
  );
};

export default ProfileForm;
