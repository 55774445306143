import React from "react";

import { Drawer } from "@mui/material";
import PropTypes from "prop-types";

const PermenantDrawer = ({ content, drawerDirection }) => {
  return (
    <Drawer
      sx={{
        width: "19vw",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "19vw",
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor={drawerDirection}
      open={true}
    >
      {content}
    </Drawer>
  );
};

PermenantDrawer.propTypes = {
  content: PropTypes.element.isRequired,
  drawerDirection: PropTypes.oneOf(["left", "right"]).isRequired,
};

export default PermenantDrawer;
