import React from "react";
import { useTranslation } from "react-i18next";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import propTypes from "prop-types";

import EmptyComponent from "../EmptyComponent";

const TransactionsTable = ({ transactions }) => {
  const { t } = useTranslation();

  let element = null;

  if (transactions.length === 0) {
    element = <EmptyComponent text={t("settingsModule.noTransactions")} />;
  }

  if (transactions.length > 0) {
    element = (
      <TableContainer
        sx={{
          marginTop: 2,
          border: "1px solid",
          borderColor: "customColors.border",
          borderRadius: 1.5,
        }}
      >
        <Table stickyHeader sx={{ width: "100%" }} aria-label="Transactions Table">
          <TableHead>
            <TableRow>
              <TableCell>{t("settingsModule.item")}</TableCell>
              <TableCell>{t("settingsModule.postedDate")}</TableCell>
              <TableCell>{t("settingsModule.amountPaid")}</TableCell>
              <TableCell>{t("settingsModule.paymentType")}</TableCell>
              <TableCell>{t("settingsModule.quantity")}</TableCell>
              <TableCell>{t("settingsModule.discount")}</TableCell>
              {/* <TableCell>{t("settingsModule.ref")}</TableCell> */}
              {/* <TableCell>{t("settingsModule.receipt")}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((item, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ minWidth: "18.75rem" }}>{item.name}</TableCell>
                <TableCell>{item.created_at}</TableCell>
                <TableCell>{item.formattedAmount}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.productQuantity}</TableCell>
                <TableCell>{item.discounts_amount}</TableCell>
                {/* <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {item.reference}
                </TableCell> */}
                {/* <TableCell>
                  <Link
                    sx={{ cursor: "pointer" }}
                    component="a"
                    variant="body2"
                    fontWeight="600"
                    href={item.receipt_url}
                    target="_blank"
                  >
                    {t("view")}
                  </Link>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return element;
};

TransactionsTable.propTypes = {
  transactions: propTypes.arrayOf(propTypes.object).isRequired,
};

export default TransactionsTable;
