import React from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "@mui/lab/LoadingButton";
import propTypes from "prop-types";

import DirectionArrow from "./DirectionArrow";

const ThemedButton = ({
  loading,
  onClick,
  disabled,
  sx,
  variant,
  fullWidth,
  disableElevation,
  withIcon,
  color,
  disableRipple,
  children,
  type = null,
  size = "medium",
}) => {
  const { i18n } = useTranslation();

  return (
    <LoadingButton
      sx={sx}
      variant={variant}
      loading={loading}
      color={color}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      security={size}
      disableRipple={disableRipple}
      disableElevation={disableElevation}
      type={type}
    >
      {children} {withIcon ? <DirectionArrow reverse={i18n.language === "en"} /> : null}
    </LoadingButton>
  );
};

ThemedButton.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  loading: propTypes.bool,
  sx: propTypes.object,
  variant: propTypes.oneOf(["contained", "outlined", "white", "text", "simpleText", "secondary"]),
  fullWidth: propTypes.bool,
  size: propTypes.oneOf(["small", "medium", "large"]),
  disableRipple: propTypes.bool,
  disableElevation: propTypes.bool,
  withIcon: propTypes.bool,
  color: propTypes.string,
  type: propTypes.string,
  children: propTypes.node,
};

export default ThemedButton;
