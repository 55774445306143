import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Link } from "@mui/material";

import NavLinkWrapper from "../Layout/NavLinkWrapper";

const settingsChildRoutes = [
  { text: "settingsModule.userProfile", path: "user-profile" },
  { text: "settingsModule.transactionHistory", path: "transaction-history" },
  { text: "settingsModule.subscriptions", path: "subscriptions" },
  { text: "settingsModule.accountSettings", path: "account-settings" },
];

const SettingsNavbar = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: (theme) => theme.spacing(1),
        border: "1px solid",
        borderRadius: 1.5,
        borderColor: "customColors.border",
        overflow: "auto",
        width: "100%",
      }}
    >
      {settingsChildRoutes.map((route, idx) => (
        <Link
          variant="tabLink"
          component={NavLinkWrapper}
          to={route.path}
          key={route.path}
          muiClassName={({ isActive }) => (isActive ? "active" : "")}
        >
          {t(route.text)}
        </Link>
      ))}
    </Box>
  );
};

export default SettingsNavbar;
