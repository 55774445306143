import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, AlertTitle, Typography, useTheme } from "@mui/material";

const PendingPaymentAlert = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Alert severity="error" sx={{ backgroundColor: theme.palette.primary.light, marginBottom: 2 }}>
      <AlertTitle sx={{ marginRight: 2 }}>{t("error")}</AlertTitle>
      <Typography variant="body1">{t("dashboardModule.pendingPayment")}</Typography>
    </Alert>
  );
};

export default PendingPaymentAlert;
