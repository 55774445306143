import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { addDays, differenceInDays, format } from "date-fns";
import propTypes from "prop-types";

import InfoCard from "../../../components/Cards/InfoCard";
import PackageCard from "../../../components/Cards/PackageCard";
import PurchaseModal from "../../../components/Modals/PurchaseModal";
import PageHeader from "../../../components/PageHeader";
import InfoCardSkeleton from "../../../components/Skeletons/InfoCardSkeleton";
import PackageCardSkeleton from "../../../components/Skeletons/PackageCardSkeleton";
import { useFirstTimePlans, useMemberContracts, useRenewalPlans } from "../../../hooks";

const Packages = ({
  onPackageStartDateChange,
  onPaymentMethodChange,
  initiatePayment,
  onCloseModal,
  onPurchasePackage,
  payment,
}) => {
  const { t } = useTranslation();
  const { apiClient, user, userHasPendingPayments, contractCharges } = useOutletContext();
  const [showRenewalPackages, setShowRenewalPackages] = useState(false);
  const [showFirstTimePurchase, setShowFirstTimePurchase] = useState(false);

  const isExpiringWithinNextMonth = (dateString) => {
    const today = new Date();
    const expirationDate = new Date(dateString);
    const daysDifference = differenceInDays(expirationDate, today);

    return daysDifference <= 30;
  };

  const { data: clientSubscriptions, isLoading: loadingSubscriptions } = useMemberContracts({
    apiClient,
    memberContracts: user?.contracts,
    memberId: user?.id,
    userHasPendingPayments,
    contractCharges,
  });

  const packageStartDate = useMemo(() => {
    if (showRenewalPackages && clientSubscriptions?.length === 1) {
      const currentContractEndDate = clientSubscriptions[0].expires_at;
      const startDate = addDays(currentContractEndDate, 1);

      return format(startDate, "yyyy-MM-dd");
    } else return format(new Date(), "yyyy-MM-dd");
  }, [showRenewalPackages, clientSubscriptions]);

  const { data: renewalPackages, isPending: isPendingRenewalPackages } = useRenewalPlans({
    apiClient,
    showRenewalPackages,
    clubId: user?.homeClubId,
  });

  const { data: firstTimePackages, isPending: loadingFirstTimePackages } = useFirstTimePlans({
    apiClient,
    clubId: user?.homeClubId,
    showFirstTimePackages: showFirstTimePurchase,
  });

  const hasAutoRenewEnabled = (subscription) => {
    const paymentTypes = subscription?.paymentPlan?.allowedPaymentTypes;
    return (
      paymentTypes?.isRecurringCreditCardPaymentAllowed &&
      !paymentTypes?.isNonRecurringPaymentAllowed
    );
  };

  const getAutoRenewSubscriptions = useCallback(
    (subscriptions) => subscriptions?.filter(hasAutoRenewEnabled) ?? [],
    [],
  );

  useEffect(() => {
    setShowFirstTimePurchase(clientSubscriptions.length === 0);

    let showRenewal = false;

    const autoRenewSubscriptions = getAutoRenewSubscriptions(clientSubscriptions);

    if (clientSubscriptions.length === 1 && autoRenewSubscriptions.length === 0) {
      // check if the current package is expiring within the next month
      const expirationDate = clientSubscriptions[0]?.expires_at;

      if (expirationDate) showRenewal = isExpiringWithinNextMonth(expirationDate);
      else showRenewal = true;
    }
    setShowRenewalPackages(showRenewal);
  }, [clientSubscriptions, getAutoRenewSubscriptions]);

  useEffect(() => {
    if (onPackageStartDateChange) onPackageStartDateChange(packageStartDate);
  }, [packageStartDate, onPackageStartDateChange]);

  let firstTimePackageElements = null;
  if (showFirstTimePurchase) {
    firstTimePackageElements = (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
        {firstTimePackages?.map((item) => {
          return (
            <PackageCard
              key={item.id}
              onPurchasePackage={onPurchasePackage}
              packageData={item}
              purchaseDisabled={userHasPendingPayments}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <Box>
      {payment.selectedPackage && (
        <PurchaseModal
          isOpen={payment.modalOpen}
          isPending={payment.loading}
          onClose={onCloseModal}
          onProceed={initiatePayment}
          paymentMethod={payment.method}
          onPaymentMethodChange={onPaymentMethodChange}
          data={{
            name: payment.selectedPackage.name,
            description: payment.selectedPackage.description,
            price: payment.selectedPackage.price,
            paymentMethods: payment.selectedPackage.paymentMethods,
            startDate: packageStartDate,
          }}
        />
      )}
      <PageHeader title={t("packages")} />
      {loadingSubscriptions && <InfoCardSkeleton />}
      {clientSubscriptions.length > 0 && (
        <>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="fontWeightMedium"
            sx={{ marginTop: 2, marginBottom: 1.5 }}
          >
            {t("packagesModule.currentPackages")}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            {clientSubscriptions.map((item) => (
              <InfoCard
                key={item.id}
                bgColor="customColors.bg2"
                title={item.name}
                subTitle={{
                  text: t("packagesModule.myPackages"),
                  target: "/settings/subscriptions",
                }}
                data={[
                  {
                    title: t("packagesModule.packagePrice"),
                    value: { text: item.formattedPrice, isLink: false, target: null },
                  },
                  {
                    title: "",
                    value: {
                      text: "",
                      isLink: false,
                      target: null,
                    },
                  },
                  {
                    title: t("purchaseDate"),
                    value: {
                      text: item.startDate,
                      isLink: false,
                      target: null,
                    },
                  },
                  {
                    title: t("expiryDate"),
                    value: {
                      text: item.expires_at,
                      isLink: false,
                      target: null,
                    },
                  },
                ]}
              />
            ))}
          </Box>
        </>
      )}
      {showRenewalPackages && isPendingRenewalPackages && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5, marginTop: 3 }}>
          <PackageCardSkeleton />
          <PackageCardSkeleton />
        </Box>
      )}
      {showRenewalPackages && !isPendingRenewalPackages && (
        <>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="fontWeightMedium"
            sx={{ marginTop: 4, marginBottom: 1.5 }}
          >
            {t("packagesModule.renewalPackages")}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            {renewalPackages.map((item) => {
              return (
                <PackageCard
                  key={item.id}
                  onPurchasePackage={onPurchasePackage}
                  packageData={item}
                  purchaseDisabled={userHasPendingPayments}
                />
              );
            })}
          </Box>
        </>
      )}
      {showFirstTimePurchase && loadingFirstTimePackages && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5, marginTop: 2 }}>
          <PackageCardSkeleton />
          <PackageCardSkeleton />
        </Box>
      )}
      {firstTimePackageElements}
    </Box>
  );
};

Packages.propTypes = {
  onPackageStartDateChange: propTypes.func.isRequired,
  onPaymentMethodChange: propTypes.func.isRequired,
  initiatePayment: propTypes.func.isRequired,
  onCloseModal: propTypes.func.isRequired,
  onPurchasePackage: propTypes.func.isRequired,
  payment: propTypes.object.isRequired,
};

export default Packages;