import React from "react";

import { Box, Skeleton } from "@mui/material";

const DashboardCardSkeleton = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "17rem",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 2,
      }}
    >
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="rectangular" width="100%" height={30} />
    </Box>
  );
};

export default DashboardCardSkeleton;
