import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

import Discount from "../Discount";
import Price from "../Price";
import ThemedButton from "../ThemedButton";
import PackageCardWrapper from "../Wrappers/PackageCardWrapper";

const PackageCard = ({ packageData, onPurchasePackage, bgColor, purchaseDisabled }) => {
  const { t, i18n } = useTranslation();

  const handlePurchasePackage = () => {
    if (onPurchasePackage) onPurchasePackage(packageData);
  };

  return (
    <PackageCardWrapper sx={bgColor ? { backgroundColor: bgColor } : {}}>
      <Typography variant="h6" fontWeight="fontWeightMedium" component="h3">
        {packageData.name[i18n.language]}
      </Typography>
      <Typography variant="subtitle2" fontWeight="fontWeightRegular" sx={{ marginBottom: 2 }}>
        {packageData.description[i18n.language]}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "start", gap: 2 }}>
        <Price value={packageData.price} />
        {packageData.discountAvailable && <Discount value={packageData.amountBeforeDiscount} />}
      </Box>

      <ThemedButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={handlePurchasePackage}
        disabled={purchaseDisabled}
      >
        {t("packagesModule.purchasePackage")}
      </ThemedButton>
    </PackageCardWrapper>
  );
};

PackageCard.propTypes = {
  packageData: propTypes.object.isRequired,
  onPurchasePackage: propTypes.func.isRequired,
  bgColor: propTypes.string,
  purchaseDisabled: propTypes.bool,
};

export default PackageCard;
