import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MenuItem, OutlinedInput, Select } from "@mui/material";
import propTypes from "prop-types";

const FormSelectInput = ({
  name,
  menuItems,
  control,
  label,
  disabled,
  rules,
  InputProps,
  fullWidth,
  sx,
  size = "medium",
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState, formState }) => (
        <Select
          displayEmpty
          value={value}
          onChange={onChange}
          placeholder={label}
          input={<OutlinedInput />}
          renderValue={(selected) => t(selected)}
          size={size}
          disabled={disabled || false}
          fullWidth={fullWidth || false}
          inputProps={InputProps}
          sx={sx}
        >
          {menuItems.map((item) => (
            <MenuItem value={item} key={item}>
              {t(item)}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

FormSelectInput.propTypes = {
  name: propTypes.string.isRequired,
  menuItems: propTypes.array.isRequired,
  control: propTypes.object.isRequired,
  label: propTypes.string.isRequired,
  disabled: propTypes.bool,
  rules: propTypes.object,
  InputProps: propTypes.object,
  size: propTypes.oneOf(["small", "medium"]),
  fullWidth: propTypes.bool,
  sx: propTypes.object,
};

export default FormSelectInput;
