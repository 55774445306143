import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Link, Skeleton, Typography, useMediaQuery } from "@mui/material";
import propTypes from "prop-types";

const ProfileInfo = ({ user }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLargerThanMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let profileImage = (
    <Skeleton
      variant="circular"
      width={50}
      height={50}
      animation="wave"
      sx={{ bgcolor: "rgba(255, 255, 255, 0.20)" }}
    />
  );
  if (user?.name) {
    if (user?.image)
      profileImage = (
        <Box
          component="img"
          src={user?.image}
          alt="Profile image"
          sx={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "50% 10%",
          }}
        />
      );
    else profileImage = <AccountCircleIcon sx={{ width: "50px", height: "50px" }} />;
  }

  let profileText = (
    <Box>
      <Skeleton
        variant="text"
        width={60}
        height={20}
        animation="wave"
        sx={{ bgcolor: "rgba(255, 255, 255, 0.20)" }}
      />
      <Skeleton
        variant="text"
        width={150}
        height={20}
        animation="wave"
        sx={{ bgcolor: "rgba(255, 255, 255, 0.20)" }}
      />
    </Box>
  );

  if (user?.name) {
    profileText = (
      <Box sx={isMobile ? { display: "flex", alignItems: "center", flexWrap: "wrap" } : {}}>
        <Typography variant="body2" fontSize={12} component="span">
          {t("welcome")},
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link variant="navlink" component={NavLink} to="settings/user-profile">
            <Typography
              variant="subtitle1"
              fontWeight="fontWeightBold"
              to="settings/user-profile"
              sx={isMobile ? { marginInlineStart: 0.5 } : {}}
            >
              {user?.name}
            </Typography>
          </Link>
          {isLargerThanMobile && (
            <Typography
              variant="body2"
              component="span"
              sx={{ margin: (theme) => theme.spacing(0, 1.5) }}
            >
              |
            </Typography>
          )}
          {!isMobile && (
            <BranchName
              branchName={user?.club.name}
              branchKeyword={t("branch")}
              lang={i18n.language}
              isMobile={isMobile}
            />
          )}
        </Box>
        {isMobile && (
          <BranchName
            branchName={user?.club.name}
            branchKeyword={t("branch")}
            lang={i18n.language}
            isMobile={isMobile}
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: 1,
      }}
    >
      {profileImage}
      {profileText}
    </Box>
  );
};

ProfileInfo.propTypes = {
  user: propTypes.object,
};

export default ProfileInfo;

const BranchName = ({ branchName, branchKeyword, lang, isMobile }) => {
  return (
    <Box sx={{ display: "flex", ...(isMobile ? { flexBasis: "100%" } : {}) }}>
      <Typography variant="body2" component="span" sx={{ marginInlineEnd: 0.5 }}>
        {branchName}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={lang === "ar" ? { marginInlineEnd: 1, order: -1 } : {}}
      >
        {branchKeyword}
      </Typography>
    </Box>
  );
};

BranchName.propTypes = {
  branchName: propTypes.string,
  branchKeyword: propTypes.string,
  lang: propTypes.string,
  isMobile: propTypes.bool,
};
