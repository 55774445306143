import React from "react";

import { Box, Skeleton } from "@mui/material";

import PageHeader from "../PageHeader";

const ClassDetailsSkeleton = () => {
  return (
    <Box>
      <PageHeader title="" prevRoute=".." />
      <Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={100}
          animation="wave"
          sx={{
            border: "1px solid",
            borderColor: "customColors.border",
            borderRadius: 1,
            marginBottom: 1,
          }}
        />
        <Skeleton variant="text" height={20} width="10%" animation="wave" />
        <Skeleton variant="text" height={20} width="100%" animation="wave" />
        <Skeleton variant="text" height={20} width="80%" animation="wave" />
        <Skeleton variant="text" height={20} width="60%" animation="wave" />
      </Box>
    </Box>
  );
};

export default ClassDetailsSkeleton;
