import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { check, cross } from "../../assets";

const OrderComplete = () => {
  const { t } = useTranslation();
  const { apiClient } = useOutletContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (searchParams.has("applepayment") && searchParams.has("id")) {
      const id = searchParams.get("id");

      apiClient
        .get(`/applepay/checkout/${id}/payment`)
        .then((response) => {
          if (response.data.status === "ok" || response.data.result.code.startsWith("000")) {
            setStatus("Ok");
          } else {
            setStatus("Error");
          }
        })
        .catch((error) => {
          setStatus("Error");
        });
    } else if (searchParams.has("status")) {
      const status = searchParams.get("status");
      if (status.toLowerCase() === "ok") {
        setStatus("Ok");
      } else {
        setStatus("Error");
      }
    } else navigate("/");
  }, [apiClient, navigate, searchParams, t]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 3,
        height: { xs: "", md: window.innerHeight - 150 },
      }}
    >
      {status === null ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            component="img"
            src={status === "Ok" ? check : cross}
            sx={{
              width: "120px",
              height: "120px",
            }}
          />
          <Typography
            variant="h1"
            fontWeight="fontWeightMedium"
            sx={{ marginTop: 2, color: status === "Ok" ? "success.main" : "error.main" }}
          >
            {status === "Ok" ? t("orderCompleteTitle") : t("orderFailedTitle")}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {status === "Ok" ? t("orderCompleteSuccessMsg") : t("orderCompleteFailedMsg")}
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to={status === "Ok" ? "/settings/subscriptions" : "/"}
            sx={{ width: "170px", marginTop: 2 }}
          >
            {t("continue")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default OrderComplete;
