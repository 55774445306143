import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import propTypes from "prop-types";

import { useDir } from "../hooks";

const PageHeader = ({ title, subtitle, prevRoute, nextLinkState }) => {
  const dir = useDir();

  let arrowIcon = <KeyboardArrowLeft fontSize="large" />;
  if (dir === "rtl") arrowIcon = <KeyboardArrowRight fontSize="large" />;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {prevRoute && (
          <Link
            variant="iconbtn"
            component={RouterLink}
            to={prevRoute}
            relative="path"
            state={nextLinkState ? nextLinkState : {}}
          >
            {arrowIcon}
          </Link>
        )}
        <Typography component="h1" variant="h1" fontWeight="fontWeightBold">
          {title}
        </Typography>
      </Box>
      <Typography color="primary" fontWeight={600} sx={{ marginTop: 0.8 }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

PageHeader.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  prevRoute: propTypes.string,
  nextLinkState: propTypes.object,
};
export default PageHeader;
