import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

const StatusBadge = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        color: (theme) => theme.palette[status === "available" ? "success" : "error"].main,
        bgcolor: (theme) => theme.palette[status === "available" ? "success" : "error"].light,
        padding: (theme) => theme.spacing(0.8, 0),
        borderRadius: 1,
        textAlign: "center",
        width: "25%",
        maxWidth: "100px",
      }}
    >
      <Typography variant="subtitle2" component="span">
        {t(`classesModule.${status}`)}
      </Typography>
    </Box>
  );
};

StatusBadge.propTypes = {
  status: propTypes.oneOf(["available", "full"]).isRequired,
};

export default StatusBadge;
