import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Modal, Typography } from "@mui/material";
import propTypes from "prop-types";

import { apple_pay, mada, tabby, tamara, visa_master } from "../../assets";
import { useBranding, useDir } from "../../hooks";
import Price from "../Price";
import ThemedButton from "../ThemedButton";
import ModalCloseIcon from "./ModalCloseIcon";

const allowedPaymentMethods = ["visa_master", "tabby", "mada", "tamara", "apple_pay"];

const PurchaseModal = ({
  isOpen,
  isPending,
  onClose,
  onProceed,
  onPaymentMethodChange,
  paymentMethod,
  data,
  children,
}) => {
  const dir = useDir();
  const { t, i18n } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    new window.TabbyCard({
      selector: "#tabbyCard", // empty div for TabbyCard
      currency: "SAR",
      lang: i18n.language,
      price: data.price,
      size: "narrow", // can be wide
      theme: "default",
      header: false,
    });
  }, [data.price, i18n.language]);

  const selectPaymentMethod = (id) => {
    if (onPaymentMethodChange) onPaymentMethodChange(id);
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const getPaymentMethodLogo = (id) => {
    switch (id) {
      case "visa_master":
        return visa_master;
      case "tabby":
        return tabby;
      case "mada":
        return mada;
      case "tamara":
        return tamara;
      case "apple_pay":
        return apple_pay;
      default:
        return "";
    }
  };

  const handleProceed = () => {
    if (!paymentMethod) return;

    if (onProceed) onProceed({ selectedPaymentMethod: paymentMethod });
  };

  return (
    <Modal keepMounted open={isOpen} onClose={handleClose} aria-labelledby="purchase-modal-title">
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          width: { xs: "90%", md: "65%" },
          maxHeight: window.innerHeight,
          top: { xs: "20%", md: "50%" },
          left: { xs: "50%", md: "47%" },
          p: 0,
          position: "absolute",
          transform: {
            xs: "translate(-50%, -20%)",
            md: "translate(-40%, -50%)",
            lg: "translate(-45%, -50%)",
          },
          backgroundColor: "customColors.modalBg",
          border: "2px solid",
          borderRadius: 1.5,
          borderColor: "customColors.border",
          boxShadow: 24,
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            backgroundColor: "#fff",
            width: "50%",
          }}
        >
          {branding && (
            <Box
              component="img"
              src={branding.assets?.darkLogo}
              alt={branding.logoAltText}
              width={100}
              height="auto"
              sx={{
                marginTop: 3,
                marginInlineStart: 3,
              }}
            />
          )}
          {branding && (
            <Box
              component="img"
              src={branding.assets?.identityGraphic}
              width={265}
              height={260}
              sx={{ marginTop: "70px", transform: dir === "rtl" ? "scaleX(-1)" : "" }}
              alt="v shaped style svg"
            />
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: "customColors.modalBg",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: 2,
            width: { xs: "100%", md: "50%" },
          }}
        >
          <ModalCloseIcon onClose={handleClose} />
          <Box>
            <Typography
              variant="h1"
              component="h1"
              fontWeight="fontWeightMedium"
              sx={{ marginTop: 3, color: "primary.main" }}
            >
              {data.name[i18n.language]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              fontWeight="fontWeightMedium"
              sx={{ marginTop: 1.5, width: "90%" }}
            >
              {data.description[i18n.language]}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" sx={{ color: "gray.dark", marginTop: 1.5 }}>
              {t("startDate")}
            </Typography>
            <Typography variant="body2">
              {new Date(data.startDate).toLocaleDateString(
                i18n.language === "en" ? "en-US" : "ar-EG",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="primary" sx={{ marginTop: 1.5, marginBottom: 1 }}>
              {t("paymentMethod")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "start", gap: 1 }}>
              {data.paymentMethods.map((item) => (
                <PaymentMethodButton
                  key={item}
                  id={item}
                  logo={getPaymentMethodLogo(item)}
                  isSelected={item === paymentMethod}
                  onSelect={() => selectPaymentMethod(item)}
                />
              ))}
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              display: paymentMethod === "tabby" ? "block" : "none",
              width: "100%",
              overflow: "auto",
              marginTop: 1,
              height: "200px",
              "& .styles__tabby-card--d5b39": {
                paddingBottom: 0,
              },
            }}
            id="tabbyCard"
          ></Box>
          <Box>
            <Typography variant="body2" sx={{ color: "gray.dark", marginTop: 2 }}>
              {t("total")}
            </Typography>
            <Price value={data.price} />
          </Box>
          {paymentMethod === "apple_pay" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                marginBottom: 2,
              }}
              component="div"
              id="applePayButtonContainer"
            >
              <form
                action={
                  branding?.publicUrl
                    ? `${branding.publicUrl}/order-complete?applepayment=true`
                    : ""
                }
                className="paymentWidgets"
                data-brands="APPLEPAY"
              ></form>
            </Box>
          ) : null}
          <ThemedButton
            variant="contained"
            fullWidth
            disabled={paymentMethod === null}
            loading={isPending}
            onClick={handleProceed}
          >
            {t("proceed")}
          </ThemedButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

PurchaseModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  isPending: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onProceed: propTypes.func.isRequired,
  onPaymentMethodChange: propTypes.func.isRequired,
  paymentMethod: propTypes.oneOf([...allowedPaymentMethods, null]),
  data: propTypes.shape({
    name: propTypes.object.isRequired,
    description: propTypes.object.isRequired,
    price: propTypes.number.isRequired,
    startDate: propTypes.string,
    paymentMethods: propTypes.arrayOf(propTypes.oneOf(allowedPaymentMethods)).isRequired,
    paymentFormUrl: propTypes.string,
  }).isRequired,
  children: propTypes.node,
};

export default PurchaseModal;

const PaymentMethodButton = ({ id, logo, isSelected, onSelect }) => {
  return (
    <Box
      onClick={onSelect}
      sx={{
        width: { xs: "75px", md: "90px" },
        height: { xs: "28px", md: "35px" },
        padding: { xs: 0.5, md: 0.8 },
        border: "1px solid",
        borderColor: isSelected ? "primary.main" : "customColors.border",
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <Box
        component="img"
        src={logo}
        alt={id}
        sx={{
          width: "auto",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

PaymentMethodButton.propTypes = {
  id: propTypes.string.isRequired,
  logo: propTypes.string.isRequired,
  isSelected: propTypes.bool.isRequired,
  onSelect: propTypes.func.isRequired,
};
