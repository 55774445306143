import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Box } from "@mui/material";

import { useBranding } from "../../hooks";
import { initialPaymentState, pay, paymentReducer } from "../../services/payments";

const ApplePayTest = () => {
  const { apiClient, user } = useOutletContext();
  const branding = useBranding();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [payment, dispatch] = useReducer(paymentReducer, initialPaymentState);

  useEffect(() => {
    if (!user || !branding) return;
    const paymentConfig = {
      startDate: "2024-12-30",
      signUpDate: "2024-12-30",
      gym_location: user?.club.cityId,
      payment_method: "apple_pay",
      publicUrl: branding?.publicUrl,
      navigate: navigate,
      memberDetails: {
        id: user.id,
        name: user.name,
        email: user.email,
        mobile: user.mobile,
        clubId: user.homeClubId,
        lang: i18n.language,
      },
      itemDetails: {
        id: 15,
        type: "paymentPlan",
        name: "1 Month Membership ACTIV Malqa Men",
        amount: 2003,
      },
    };
    pay({ config: paymentConfig, dispatch, apiClient });
  }, [apiClient, branding, dispatch, i18n.language, navigate, user]);

  return (
    <Box>
      <form
        action={branding?.publicUrl ? `${branding.publicUrl}/order-complete?applepayment=true` : ""}
        className="paymentWidgets"
        data-brands="APPLEPAY"
      ></form>
    </Box>
  );
};

export default ApplePayTest;
