import { initReactI18next } from "react-i18next";

import i18n from "i18next";

import ar from "./locales/ar";
import en from "./locales/en";

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    ar: ar,
  },
  lng: localStorage.getItem("lang") || "ar",
  fallbackLng: "en",
  debug: false,

  interpolation: {
    escapeValue: false,
  },
});

export const toggleLanguage = () => {
  localStorage.setItem("lang", i18n.language === "en" ? "ar" : "en");
  i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
};
