import React from "react";

import { Box } from "@mui/material";
import propTypes from "prop-types";

const PackageCardWrapper = ({ children, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: "customColors.bg2",
        width: { xs: "100%", md: "calc(100% / 2 - 20px)", lg: "calc(100% / 3 - 20px)" },
        padding: (theme) => theme.spacing(2, 3),
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

PackageCardWrapper.propTypes = {
  children: propTypes.node.isRequired,
  sx: propTypes.object,
};

export default PackageCardWrapper;
