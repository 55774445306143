import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import propTypes from "prop-types";

import DataComponent from "../DataComponent";

const PaymentCard = ({ payment }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "customColor.bg1",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        padding: 2,
        paddingBottom: 0,
      }}
    >
      <DataComponent
        title={t("settingsModule.postedDate")}
        value={{ text: payment.created_at, isLink: false, target: null }}
      />
      <DataComponent
        title={t("settingsModule.item")}
        value={{ text: payment.name, isLink: false, target: null }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DataComponent
          title={t("settingsModule.amountPaid")}
          value={{ text: payment.formattedAmount, isLink: false, target: null }}
        />
        {/* <DataComponent
          title={t("settingsModule.receipt")}
          value={{ text: t("view"), isLink: true, target: payment.receipt_url }}
        /> */}
      </Box>
    </Box>
  );
};

PaymentCard.propTypes = {
  payment: propTypes.object.isRequired,
};

export default PaymentCard;
