import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, Typography, useMediaQuery } from "@mui/material";

import FormInput from "../../../components/Form/FormInput";
import ThemedButton from "../../../components/ThemedButton";
import { changeAccountPassword } from "../../../services/api";

const AccountSettings = () => {
  const { t } = useTranslation();
  const { apiClient } = useOutletContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: changeAccountPassword,
    onSuccess: () => {
      toast.success(t("settingsModule.passwordChanged"));
      reset({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    },
    onError: (error) => {
      toast.error(t("somethingWentWrong"));
    },
  });

  const onSubmit = (data) => {
    mutate({
      apiClient: apiClient,
      data: {
        password: data.currentPassword,
        newPassword: data.newPassword,
      },
    });
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2, 0),
      }}
    >
      <Box sx={{ width: isMobile ? "100%" : "24rem", marginTop: 2 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          {t("settingsModule.currentPassword")}
        </Typography>
        <FormInput
          name="currentPassword"
          control={control}
          label={t("settingsModule.currentPassword")}
          type={showCurrentPassword ? "text" : "password"}
          rules={{ required: t("settingsModule.currentPasswordRequired") }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={() => setShowCurrentPassword((state) => !state)}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ width: isMobile ? "100%" : "24rem", marginTop: 2 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          {t("settingsModule.newPassword")}
        </Typography>
        <FormInput
          name="newPassword"
          control={control}
          label={t("settingsModule.newPassword")}
          type={showNewPassword ? "text" : "password"}
          rules={{
            required: t("settingsModule.enterNewPassword"),
            minLength: { value: 4, message: t("settingsModule.passwordMinLength") },
            maxLength: { value: 39, message: t("settingsModule.passwordMaxLength") },
          }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPassword((state) => !state)}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ width: isMobile ? "100%" : "24rem", marginTop: 2 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          {t("settingsModule.confirmPassword")}
        </Typography>
        <FormInput
          name="confirmPassword"
          control={control}
          label={t("settingsModule.confirmPassword")}
          type={showConfirmPassword ? "text" : "password"}
          rules={{
            required: t("settingsModule.enterPasswordConfirm"),
            minLength: { value: 4, message: t("settingsModule.passwordMinLength") },
            maxLength: { value: 39, message: t("settingsModule.passwordMaxLength") },
            validate: (value, formValues) =>
              value === formValues.newPassword || t("settingsModule.passwordsDontMatch"),
          }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm new password visibility"
                  onClick={() => setShowConfirmPassword((state) => !state)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <ThemedButton
        variant="contained"
        disabled={!isDirty}
        loading={isPending}
        sx={{
          margin: (theme) => theme.spacing(2, 0),
          marginTop: 4,
          padding: (theme) => theme.spacing(2, 0),
          width: isMobile ? "100%" : "24rem",
          fontWeight: "bold",
        }}
        onClick={handleSubmit(onSubmit)}
      >
        {t("saveChanges")}
      </ThemedButton>
    </Box>
  );
};

export default AccountSettings;
