import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

/**
 *
 * @param {Object} data object containing date (number || string), pattern (string), and locale "date-fns locale" (string)
 * @returns formatted date string
 */
export const formatDate = ({ date, pattern, locale }) => {
  if (!date) return "";
  return format(date, pattern, { locale });
};

export const formatDateInRiyadhZone = (date, pattern = "yyyy-MM-dd", config = {}) => {
  if (!date) return "";

  return formatInTimeZone(date, "Asia/Riyadh", pattern, config);
};
