import React from "react";

import { Box, Skeleton } from "@mui/material";

const InfoCardSkeleton = () => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        width: { xs: "100%", md: "calc(100% / 2 - 20px)", lg: "calc(100% / 3 - 20px)" },
        padding: (theme) => theme.spacing(2),
      }}
    >
      <Skeleton variant="text" width="30%" animation="wave" />
      <Skeleton
        variant="rectangular"
        sx={{ marginTop: 2 }}
        width="30%"
        height={20}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        sx={{ marginTop: 2 }}
        width="30%"
        height={20}
        animation="wave"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: 2 }}
          width="30%"
          height={20}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          sx={{ marginTop: 2 }}
          width="30%"
          height={20}
          animation="wave"
        />
      </Box>
    </Box>
  );
};

export default InfoCardSkeleton;
