import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext, useParams } from "react-router-dom";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

import PageHeader from "../../components/PageHeader";
import TrainerSkeleton from "../../components/Skeletons/TrainerSkeleton";
import { useTrainerDetails } from "../../hooks";

const Trainer = () => {
  const { t } = useTranslation();
  const { trainerID } = useParams();
  const { state } = useLocation();
  const { apiClient } = useOutletContext();

  const { data: trainer, isPending: isTrainerPending } = useTrainerDetails({
    apiClient,
    trainerID: trainerID,
  });

  let trainerElement = null;

  if (isTrainerPending) trainerElement = <TrainerSkeleton />;
  else if (trainer) {
    trainerElement = (
      <Box
        sx={{
          backgroundColor: "customColors.bg3",
          border: "1px solid",
          borderColor: "customColors.border",
          borderRadius: 2,
          padding: (theme) => theme.spacing(3, 2),
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {trainer.photoUrl ? (
            <Box
              component="img"
              src={trainer.photoUrl}
              alt={trainer.firstName + " " + trainer.lastName}
              sx={{
                width: "115px",
                height: "115px",
                objectFit: "cover",
                borderRadius: 1,
                marginInlineEnd: 1,
              }}
            />
          ) : (
            <AccountBoxIcon sx={{ width: "115px", height: "115px" }} />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="link" fontWeight={600} color="primary">
              {`${trainer.firstName} ${trainer.lastName}`}
            </Typography>
            {/* <Typography variant="body2" fontSize={12}>
              {trainer.email}
            </Typography> */}
          </Box>
        </Box>
        {/* {trainer.bio && <BorderedDataBox title={t("aboutTrainer")} value={trainer.bio} />}
        {trainer.qualifications?.length > 0 && (
          <BorderedDataBox title={t("qualifications")} value={trainer.qualifications} />
        )} */}
      </Box>
    );
  }

  return (
    <Box>
      <PageHeader
        title={t("trainer")}
        prevRoute={state?.prevRoute || "/"}
        nextLinkState={state?.nextLinkState}
      />
      {trainerElement}
    </Box>
  );
};

export default Trainer;

const BorderedDataBox = ({ title, value }) => {
  return (
    <Box
      sx={{
        marginTop: 3,
        paddingInlineStart: 1,
        borderInlineStart: (theme) => `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <Typography
        variant="body2"
        color="primary"
        fontWeight="fontWeightMedium"
        sx={{ fontSize: "0.8rem" }}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ margin: (theme) => theme.spacing(1, 0), marginTop: 0 }}>
        {value}
      </Typography>
    </Box>
  );
};

BorderedDataBox.propTypes = {
  title: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};
