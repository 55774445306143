import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { compareAsc, differenceInMinutes } from "date-fns";

import { useBranding } from "../hooks";
import {
  getCities,
  getClassBookings,
  getClassByID,
  getClasses,
  getContractCharges,
  getContractPayments,
  getFirstTimePackages,
  getMemberProducts,
  getMemberProfile,
  getMemberProfilePhoto,
  getPtPackages,
  getRenewalPackages,
  getStates,
  getTrainerByID,
  getTransactionHistory,
} from "../services/api";
import { formatDateInRiyadhZone } from "../utils/helperFunctions";
import {
  useClassesTranslations,
  usePaymentPlanTranslations,
  useProductTranslations,
} from "./language";

export const useMemberProfile = ({ apiClient, memberId }) => {
  const {
    data: profile,
    isPending,
    refetch,
  } = useQuery({
    queryKey: ["profile"],
    queryFn: ({ signal }) => {
      return getMemberProfile({ signal, apiClient, memberId: memberId });
    },
    // staleTime: 60 * 1000,
    enabled: !!memberId,
  });

  const user = useMemo(() => {
    if (isPending || !profile?.id) return null;

    let hasActiveContract = false;
    if (profile.contracts?.length) {
      hasActiveContract = profile.contracts.some((contract) => {
        return contract.status === "Current";
      });
    }

    return {
      id: profile.id,
      email: profile.email,
      homeClubId: profile.homeClubId,
      hasActiveContract: hasActiveContract,
      name: `${profile.firstName} ${profile.lastName}`,
      firstName: profile.firstName,
      lastName: profile.lastName,
      mobile: profile.phoneNumber,
      gender: profile.sex?.toLowerCase(), // accepted values: "male" or "female"
      dateOfBirth: profile.birthdate || "",
      nationalID: profile.personalId || "",
      address: {
        street: profile.address?.street || "",
        postalCode: profile.address?.postalCode || "",
        city: profile.address?.city || "",
        countrySymbol: "SA",
        country: profile.address?.country || "",
        state: profile.address?.state || "",
      },
      club: {
        id: profile.homeClub?.id || "",
        name: profile.homeClub?.name || "",
        cityId: profile.homeClub?.city.id || "",
      },
      photos: profile.photos,
      contracts: profile.contracts,
      profilePhotoId: profile.photos?.find((photo) => photo.size === "Medium")?.fileId ?? "",
    };
  }, [isPending, profile]);

  return { data: user, refetchMemberProfile: refetch, isPending };
};

export const useMemberProfilePhoto = ({ apiClient, fileId }) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["profilePhoto"],
    queryFn: ({ signal }) => {
      return getMemberProfilePhoto({ signal, apiClient, fileId });
    },
    enabled: !!fileId,
  });

  return { data, isPending, refetch };
};

export const useTransactionHistory = ({ apiClient, memberId }) => {
  const { i18n } = useTranslation();

  const { data, isPending } = useQuery({
    queryKey: ["transactions"],
    queryFn: ({ signal }) => {
      return getTransactionHistory({ signal, apiClient, memberId: memberId });
    },
    staleTime: 5 * 60 * 1000,
    enabled: !!memberId,
  });

  const transactions = useMemo(() => {
    if (isPending || !data) return [];

    const dateOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return data.map((item) => {
      return {
        ...item,
        created_at: new Intl.DateTimeFormat(
          `${i18n.language === "en" ? "en-UK" : "ar-EG"}`,
          dateOptions,
        ).format(new Date(item.createdDate)),
        discounts_amount: new Intl.NumberFormat(i18n.language, {
          style: "currency",
          currency: "SAR",
        }).format(item.transaction.discountAmountGross),
        formattedAmount: new Intl.NumberFormat(i18n.language, {
          style: "currency",
          currency: "SAR",
        }).format(item.amount.gross),
        productQuantity: item.transaction.productQuantity || "-",
        name: item.transaction.description,
      };
    });
  }, [data, i18n.language, isPending]);

  return { data: transactions, isPending };
};

export const useMemberProducts = ({ apiClient, memberId }) => {
  const { i18n } = useTranslation();

  const { data: translations, isLoading: translationsLoading } = useProductTranslations({
    apiClient,
  });

  const { data, isPending } = useQuery({
    queryKey: ["memberProducts"],
    queryFn: ({ signal }) => getMemberProducts({ signal, apiClient, memberId: memberId }),
    staleTime: 5 * 60 * 1000,
    enabled: !!memberId,
  });

  const products = useMemo(() => {
    if (isPending || !data || translationsLoading) return [];

    const filteredProducts = [];

    data.forEach((item) => {
      if (item.currentQuantity) {
        filteredProducts.push({
          ...item,
          cardType: "ptSessions",
          isConsumable: true,
          name: translations[item.productId]?.name || { en: "", ar: "" },
          description: translations[item.productId]?.description || { en: "", ar: "" },
          purchaseDate: formatDateInRiyadhZone(item.purchaseDate, "yyyy-MM-dd"),
          expires_at: item.expiryDate
            ? formatDateInRiyadhZone(new Date(item.expiryDate), "yyyy-MM-dd")
            : "-",
          formattedPrice: new Intl.NumberFormat(i18n.language, {
            style: "currency",
            currency: "SAR",
          }).format(item.product.defaultPriceGross),
        });
      }
    });

    return filteredProducts;
  }, [isPending, data, translationsLoading, translations, i18n.language]);

  return { data: products, isPending: isPending || translationsLoading };
};

export const useContractPayments = ({ apiClient, memberId }) => {
  const { data: payments, isPending } = useQuery({
    queryKey: ["contractPayments"],
    queryFn: ({ signal }) =>
      getContractPayments({
        signal,
        apiClient,
        memberId: memberId,
      }),
    enabled: !!memberId,
  });

  return { data: payments, isPending };
};

export const useMemberContracts = ({
  apiClient,
  memberContracts,
  userHasPendingPayments,
  contractCharges,
  memberId,
}) => {
  const { i18n } = useTranslation();

  const { data: contractPayments, isPending: paymentsPending } = useContractPayments({
    apiClient,
    memberId,
  });

  const { data: paymentPlanTranslations, isLoading: translationsLoading } =
    usePaymentPlanTranslations({ apiClient });

  const contracts = useMemo(() => {
    if (!memberContracts || translationsLoading || paymentsPending) return [];

    const result = [];

    memberContracts.forEach((item) => {
      let contractIsPendingPayment = false;
      if (userHasPendingPayments) {
        const pendingPayment = contractCharges.find((charge) => charge.contractId === item.id);

        if (pendingPayment) contractIsPendingPayment = true;
      }

      if (contractIsPendingPayment) return;

      const contractPayment = contractPayments.reduce((acc, curr) => {
        if (curr.contractId === item.id) {
          return acc + curr.amount.gross;
        } else return acc;
      }, 0);

      let endDate = null;

      if (!item.endDate) {
        const paymentInterval = item.paymentPlan.paymentInterval;
        const startDate = item.startDate;

        // Convert startDate to a Date object
        const date = new Date(startDate);

        // Add months and days based on the interval
        date.setMonth(date.getMonth() + paymentInterval.months);
        date.setDate(date.getDate() + paymentInterval.days);
        endDate = date;
      }

      const itemTranslation = paymentPlanTranslations[item.paymentPlan.id] || {
        name: {
          en: item.paymentPlan.name || "",
          ar: item.paymentPlan.name || "",
        },
        description: {
          en: item.paymentPlan.description || "",
          ar: item.paymentPlan.description || "",
        },
      };

      result.push({
        ...item,
        cardType: "contracts",
        name: itemTranslation.name,
        description: itemTranslation.description,
        isConsumable: false,
        startDate: formatDateInRiyadhZone(new Date(item.startDate), "yyyy-MM-dd"),
        expires_at: formatDateInRiyadhZone(new Date(item.endDate || endDate), "yyyy-MM-dd"),
        formattedPrice: contractPayment
          ? new Intl.NumberFormat(i18n.language, {
              style: "currency",
              currency: "SAR",
            }).format(contractPayment)
          : "",
      });
    });

    return result;
  }, [
    contractCharges,
    contractPayments,
    i18n.language,
    memberContracts,
    paymentPlanTranslations,
    paymentsPending,
    translationsLoading,
    userHasPendingPayments,
  ]);

  return { data: contracts, isLoading: translationsLoading || paymentsPending };
};

export const useRenewalPlans = ({ apiClient, clubId, showRenewalPackages }) => {
  const { data: plans, isPending: isPlansPending } = useQuery({
    queryKey: ["renewalPackages"],
    queryFn: ({ signal }) =>
      getRenewalPackages({
        signal,
        apiClient,
        clubId: clubId,
      }),
    staleTime: 5 * 60 * 1000,
    enabled: showRenewalPackages && !!clubId,
  });

  const renewalPlans = useMemo(() => {
    if (!plans || isPlansPending) return [];

    const items = plans.map((item) => {
      let isAutomaticRenew = false;
      const automaticRenewAttribute = item.customAttributes?.find(
        (attr) => attr.displayName === "Automatic Renewal",
      );

      if (automaticRenewAttribute) {
        isAutomaticRenew = automaticRenewAttribute.rawValue === "True" ? true : false;
      }

      const arTranslation = item.translations.find(
        (translation) => translation.language.toLowerCase() === "ar",
      );
      const enTranslation = item.translations.find(
        (translation) => translation.language.toLowerCase() === "en",
      );

      const itemTranslation = {
        name: {
          en: enTranslation?.name || "",
          ar: arTranslation?.name || "",
        },
        description: {
          en: enTranslation?.description || "",
          ar: arTranslation?.description || "",
        },
      };
      const discountAvailable =
        item.availableContractDiscountDefinitions.length > 0 &&
        item.amountAfterDiscount < item.amountBeforeDiscount;

      const itemPrice = discountAvailable ? item.amountAfterDiscount : item.amountBeforeDiscount;

      return {
        ...item,
        automaticRenew: isAutomaticRenew,
        name: itemTranslation.name,
        description: itemTranslation.description,
        price: itemPrice,
        discountAvailable: discountAvailable,
        itemType: "paymentPlan", // needed for the payment process
      };
    });

    return items.sort((a, b) => a.price - b.price);
  }, [plans, isPlansPending]);

  return { data: renewalPlans, isPending: isPlansPending };
};

export const useFirstTimePlans = ({ apiClient, clubId, showFirstTimePackages }) => {
  const { data: plans, isPending: plansLoading } = useQuery({
    queryKey: ["firstTimePackages"],
    queryFn: ({ signal }) =>
      getFirstTimePackages({
        signal,
        apiClient,
        clubId: clubId,
      }),
    staleTime: 5 * 60 * 1000,
    enabled: showFirstTimePackages && !!clubId,
  });

  const firstTimePlans = useMemo(() => {
    if (!plans || plansLoading) return [];

    const items = plans.map((item) => {
      let isAutomaticRenew = false;
      const automaticRenewAttribute = item.customAttributes?.find(
        (attr) => attr.displayName === "Automatic Renewal",
      );

      if (automaticRenewAttribute) {
        isAutomaticRenew = automaticRenewAttribute.rawValue === "True" ? true : false;
      }

      const arTranslation = item.translations.find(
        (translation) => translation.language.toLowerCase() === "ar",
      );
      const enTranslation = item.translations.find(
        (translation) => translation.language.toLowerCase() === "en",
      );

      const itemTranslation = {
        name: {
          en: enTranslation?.name || "",
          ar: arTranslation?.name || "",
        },
        description: {
          en: enTranslation?.description || "",
          ar: arTranslation?.description || "",
        },
      };

      const discountAvailable =
        item.availableContractDiscountDefinitions.length > 0 &&
        item.amountAfterDiscount < item.amountBeforeDiscount;

      const itemPrice = discountAvailable ? item.amountAfterDiscount : item.amountBeforeDiscount;

      return {
        ...item,
        automaticRenew: isAutomaticRenew,
        name: itemTranslation.name,
        description: itemTranslation.description,
        price: itemPrice,
        discountAvailable: discountAvailable,
        itemType: "paymentPlan", // needed for the payment process
      };
    });

    return items.sort((a, b) => a.price - b.price);
  }, [plans, plansLoading]);

  return { data: firstTimePlans, isPending: plansLoading };
};

export const usePtProductBundles = ({ apiClient }) => {
  const { data: productTranslations, isLoading: translationsLoading } = useProductTranslations({
    apiClient,
  });

  const { data: products, isLoading: productsLoading } = useQuery({
    queryKey: ["ptPackages"],
    queryFn: ({ signal }) =>
      getPtPackages({
        signal,
        apiClient,
      }),
    staleTime: 5 * 60 * 1000,
  });

  const ptProductsData = useMemo(() => {
    if (!products || productsLoading || translationsLoading) return [];

    const items = products.map((item) => {
      return {
        ...item,

        name: productTranslations[item.id]?.name || { en: "", ar: "" },
        description: productTranslations[item.id]?.description || { en: "", ar: "" },
        price: item.defaultPriceGross,
        startDate: formatDateInRiyadhZone(new Date(), "yyyy-MM-dd"),
        itemType: "ptSession", // needed for the payment process
      };
    });

    return items.sort((a, b) => a.price - b.price);
  }, [productTranslations, products, productsLoading, translationsLoading]);

  return { data: ptProductsData, isLoading: productsLoading || translationsLoading };
};

export const useAvailableClasses = ({ apiClient, dateFnsLocale, memberId, clubId, startDate }) => {
  const { t, i18n } = useTranslation();

  const { data: translations, isLoading: translationsLoading } = useClassesTranslations({
    apiClient,
  });

  const { data, isPending, isError } = useQuery({
    queryKey: ["classes", startDate],
    queryFn: ({ signal }) => {
      return getClasses({
        apiClient,
        signal,
        memberId: memberId,
        homeClubId: clubId,
        startDate: startDate,
      });
    },
    enabled: !!memberId && !!clubId,
  });

  const classesData = useMemo(() => {
    if (isPending || !data || translationsLoading) return [];

    return data.map((item, index) => {
      // calculate class duration
      const duration = differenceInMinutes(new Date(item.endDate), new Date(item.startDate));

      let classStatus = "available";
      if (item.attendeesLimit !== null && item.attendeesCount >= item.attendeesLimit) {
        classStatus = "full";
      }

      return {
        title: translations[item.classType.id].name[i18n.language],
        id: item.id,
        status: classStatus,
        details: [
          {
            name: "trainer",
            value: (item.instructor.firstName || "-") + " " + (item.instructor.lastName || "-"),
            id: item.instructorId,
            linkState: { prevRoute: `/classes` },
          },
          { name: "grpSize", value: item.attendeesLimit || "-" },
          {
            name: "time",
            value: formatDateInRiyadhZone(new Date(item.startDate), "hh:mm aa", {
              locale: dateFnsLocale,
            }),
          },
          { name: "duration", value: duration + ` ${t("mins")}` },
        ],
      };
    });
  }, [data, dateFnsLocale, i18n.language, isPending, t, translations, translationsLoading]);

  return { data: classesData, isPending, isError };
};

export const useClassBookings = ({ apiClient, memberId }) => {
  const { data: translations, isLoading: translationsLoading } = useClassesTranslations({
    apiClient,
  });

  const { data: classBookings, isPending: isBookingsPending } = useQuery({
    queryKey: ["classBookings"],
    queryFn: ({ signal }) =>
      getClassBookings({
        signal,
        apiClient,
        memberId: memberId,
        today: formatDateInRiyadhZone(new Date(), "yyyy-MM-dd"),
      }),
    enabled: !!memberId,
  });

  const data = useMemo(() => {
    if (isBookingsPending || translationsLoading) return [];

    const sortedBookings = classBookings.sort((a, b) =>
      compareAsc(new Date(a.class.startDate), new Date(b.class.startDate)),
    );

    return sortedBookings?.map((item) => {
      const duration = differenceInMinutes(
        new Date(item.class.endDate),
        new Date(item.class.startDate),
      );

      return {
        ...item,
        class: {
          ...item.class,
          title: translations[item.class.classTypeId].name || {
            en: item.class.classType?.name || "",
            ar: item.class.classType?.name || "",
          },
          duration: duration,
          instructorName: item.class.instructor?.id
            ? `${item.class.instructor.firstName} ${item.class.instructor.lastName}`
            : "-",
        },
      };
    });
  }, [classBookings, isBookingsPending, translations, translationsLoading]);

  return { data, isPending: isBookingsPending || translationsLoading };
};

export const useClassDetails = ({ apiClient, dateFnsLocale, classID, memberId }) => {
  const { t, i18n } = useTranslation();
  const branding = useBranding();

  const { data: translations, isLoading: translationsLoading } = useClassesTranslations({
    apiClient,
  });

  const { data, isPending } = useQuery({
    queryKey: ["class", { classID }],
    queryFn: ({ signal }) => getClassByID({ signal, apiClient, classID, memberId }),
    staleTime: 5 * 60 * 1000,
    enabled: !!memberId,
  });

  const classData = useMemo(() => {
    if (!data || isPending || translationsLoading) return null;

    // calculate class duration
    const differenceInMilliseconds = new Date(data.endDate) - new Date(data.startDate);
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    let classStatus = "available";
    if (data.attendeesLimit !== null && data.attendeesCount >= data.attendeesLimit) {
      classStatus = "full";
    }

    return {
      ...data,
      name: translations[data.classType.id].name[i18n.language],
      description: translations[data.classType.id].description[i18n.language],
      groupSize: data.attendeesLimit === null ? "-" : data.attendeesLimit,
      time: formatDateInRiyadhZone(new Date(data.startDate), "hh:mm aa", {
        locale: dateFnsLocale,
      }),
      duration: `${differenceInMinutes} ${t("mins")}`,
      image: data.classType?.photoUrl ? data.classType.photoUrl : branding?.assets.classFallback,
      // location: data.occurrencelocation_name,
      status: classStatus,
    };
  }, [
    branding,
    data,
    dateFnsLocale,
    i18n.language,
    isPending,
    t,
    translations,
    translationsLoading,
  ]);

  return { data: classData, isPending };
};

export const useTrainerDetails = ({ apiClient, trainerID }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["trainer", { trainerID: trainerID }],
    queryFn: ({ signal }) => getTrainerByID({ signal, apiClient, trainerID: trainerID }),
    staleTime: 5 * 60 * 1000,
  });

  return { data, isLoading };
};

export const useSaudiStates = ({ apiClient }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["states"],
    queryFn: ({ signal }) =>
      getStates({
        signal,
        apiClient,
      }),
    staleTime: Infinity,
  });

  return { data, isLoading };
};

export const useSaudiCities = ({ apiClient }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["cities"],
    queryFn: ({ signal }) =>
      getCities({
        signal,
        apiClient,
      }),
    staleTime: Infinity,
  });

  return { data, isLoading };
};

export const usePendingPayments = ({ apiClient, memberId }) => {
  const { data, isPending } = useQuery({
    queryKey: ["contractCharges"],
    queryFn: ({ signal }) =>
      getContractCharges({
        signal,
        apiClient,
        memberId,
      }),
    enabled: !!memberId,
  });

  return { data, isPending };
};
