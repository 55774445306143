import React from "react";
import { useOutletContext } from "react-router-dom";

import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import TransactionsTable from "../../../components/TransactionHistory/TransactionsTable";
import { useTransactionHistory } from "../../../hooks";

const TransactionHistory = () => {
  const { apiClient, user } = useOutletContext();

  const { data: transactions, isPending } = useTransactionHistory({
    apiClient,
    memberId: user?.id,
  });

  return isPending ? <TableSkeleton /> : <TransactionsTable transactions={transactions} />;
};

export default TransactionHistory;
