import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import InfoCard from "../../../components/Cards/InfoCard";
import CardSlider from "../../../components/CardSlider/CardSlider";
import DashboardCardSkeleton from "../../../components/Skeletons/DashboardCardSkeleton";
import ThemedButton from "../../../components/ThemedButton";
import { useBranding, useClassBookings } from "../../../hooks";

const Appointments = () => {
  const { t } = useTranslation();
  const { user, apiClient, dateFnsLocale } = useOutletContext();

  const { data: bookingsData, isPending: isBookingsPending } = useClassBookings({
    apiClient,
    memberId: user?.id,
  });

  const bookingsCards = useMemo(() => {
    if (isBookingsPending) return [];

    return bookingsData?.map((item) => {
      return (
        <InfoCard
          key={item.id}
          fullWidth
          bgColor="customColors.bg2"
          title={item.class.title}
          subTitle={{
            text: "",
            target: "",
          }}
          data={[
            {
              title: t("classesModule.trainer"),
              value: {
                text: item.class.instructorName,
                isLink: true,
                target: `/trainer/${item.class.instructor?.id || ""}`,
                linkState: { prevRoute: "/" },
              },
            },
            {
              title: t("classesModule.grpSize"),
              value: {
                text: `${item.class.attendeesLimit}`,
                isLink: false,
                target: null,
              },
            },
            {
              title: t("classesModule.time"),
              value: {
                text: format(item.class.startDate, "hh:mm aa", { locale: dateFnsLocale }),
                isLink: false,
                target: null,
              },
            },
            {
              title: t("classesModule.duration"),
              value: {
                text: `${item.class.duration} ${t("mins")}`,
                isLink: false,
                target: null,
              },
            },
          ]}
        />
      );
    });
  }, [bookingsData, dateFnsLocale, isBookingsPending, t]);

  let element = <NoAppointmentsCard />;
  if (bookingsCards?.length) element = <CardSlider cards={bookingsCards} />;

  return (
    <Box sx={{ flex: 1, minWidth: "18rem", marginBottom: { xs: 2, md: 0 } }}>
      <Typography variant="h5" component="h2" fontWeight="fontWeightBold" sx={{ marginBottom: 1 }}>
        {t("dashboardModule.appointments")}
      </Typography>
      {isBookingsPending && <DashboardCardSkeleton />}
      {!isBookingsPending && element}
    </Box>
  );
};

export default Appointments;

const NoAppointmentsCard = () => {
  const branding = useBranding();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlenavigate = () => navigate("/classes");

  return (
    <Box
      sx={{
        backgroundColor: "customColors.bg1",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "end",
        height: "17rem",
        padding: 2,
      }}
    >
      {branding && (
        <Box component="img" src={branding.assets?.calendar} width={50} sx={{ marginBottom: 1 }} />
      )}
      <Typography
        variant="body1"
        fontWeight="fontWeightMedium"
        sx={{ width: "90%", textAlign: "center" }}
      >
        {t("dashboardModule.noAppointments")}
      </Typography>
      <ThemedButton
        variant="contained"
        color="secondary"
        fullWidth
        withIcon
        onClick={handlenavigate}
        sx={{ marginTop: 1, justifyContent: "space-between" }}
      >
        {t("dashboardModule.bookClass")}
      </ThemedButton>
    </Box>
  );
};
