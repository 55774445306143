import React from "react";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

const EmptyComponent = ({ text }) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.action.hover,
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        fontWeight="fontWeightMedium"
        component="h2"
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        {text}
      </Typography>
    </Box>
  );
};

EmptyComponent.propTypes = {
  text: propTypes.string.isRequired,
};

export default EmptyComponent;
