import React from "react";

import { Box, Skeleton } from "@mui/material";

const TrainerSkeleton = () => {
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(3, 2),
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={115}
          height={115}
          sx={{
            borderRadius: 1,
            marginInlineEnd: 1,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Skeleton variant="text" width={200} height={20} animation="wave" />
          <Skeleton variant="text" width={150} height={15} animation="wave" />
        </Box>
      </Box>
      <SkeletonBlock />
      <SkeletonBlock />
    </Box>
  );
};

export default TrainerSkeleton;

const SkeletonBlock = () => {
  return (
    <Box sx={{ marginTop: 3 }}>
      <Skeleton variant="text" width={150} height={15} animation="wave" />
      <Skeleton variant="text" width="100%" height={20} animation="wave" />
      <Skeleton variant="text" width="100%" height={20} animation="wave" />
      <Skeleton variant="text" width="60%" height={20} animation="wave" />
    </Box>
  );
};
