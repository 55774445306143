import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";

import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { ar, enGB } from "date-fns/locale";

import Navigation from "../../components/Layout/Navigation";
import Loading from "../../components/Loading";
import { useMemberProfile, usePendingPayments } from "../../hooks";
import { useAuth } from "../../utils/AuthProvider";

const Layout = () => {
  const { auth, axiosInstance } = useAuth();
  const { i18n } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg")) && !isMobile;
  const dateFnsLocale = i18n.language === "ar" ? ar : enGB;

  const { data: user, refetchMemberProfile } = useMemberProfile({
    apiClient: axiosInstance,
    memberId: auth?.memberId,
  });

  const { data: contractCharges } = usePendingPayments({
    apiClient: axiosInstance,
    memberId: user?.id,
  });

  let element = <Loading />;

  if (auth) {
    if (auth.isAuthenticated) {
      element = (
        <>
          <Navigation user={user} />
          <Toolbar />
          {isMobile && <Toolbar />}
          <Box
            component="main"
            open={true}
            sx={{
              flexGrow: 1,
              padding: isMobile ? theme.spacing(6, 2) : theme.spacing(5, 2),
              marginInlineStart: isMobile ? "0" : "19vw",
            }}
          >
            <Outlet
              context={{
                apiClient: axiosInstance,
                viewport: { isMobile, isTablet },
                dateFnsLocale,
                user,
                refetchMemberProfile,
                userHasPendingPayments: contractCharges?.length > 0 ? true : false,
                contractCharges: contractCharges,
              }}
            />
          </Box>
        </>
      );
    } else if (auth.isAuthenticated === false) {
      element = <Navigate to="/login" replace={true} />;
    }
  }

  return element;
};

export default Layout;
