import React from "react";

import { Box, Skeleton, useMediaQuery } from "@mui/material";

const ClassCardSkeleton = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("lg")) && !isMobile;
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderColor: (theme) => theme.palette.gray.light,
        minWidth: isMobile ? "100%" : isTablet ? "calc(100% / 2 - 20px)" : "calc(100% / 3 - 20px)",
        maxWidth: isMobile ? "100%" : isTablet ? "calc(100% / 2)" : "calc(100% / 3 - 5px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: (theme) => theme.spacing(1.5),
          height: "65px",
        }}
      >
        <Skeleton variant="text" width="30%" sx={{ marginInlineStart: 1 }} animation="wave" />
        <Skeleton variant="text" width="30%" sx={{ marginInlineEnd: 1 }} animation="wave" />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={150} animation="wave" />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        animation="wave"
        sx={{ margin: (theme) => theme.spacing(1, 0) }}
      />
    </Box>
  );
};

export default ClassCardSkeleton;
