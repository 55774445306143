import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";

import { Box, Button, MobileStepper } from "@mui/material";
import propTypes from "prop-types";

import { useDir } from "../../hooks";
import DirectionArrow from "../DirectionArrow";

const CardSlider = ({ cards }) => {
  const { i18n } = useTranslation();
  const dir = useDir();

  const [activeStep, setActiveStep] = useState(0);

  const handleNextSwipe = () => {
    if (dir === "ltr") {
      if (activeStep < cards.length - 1) setActiveStep((state) => state + 1);
    } else {
      if (activeStep > 0) setActiveStep((state) => state - 1);
    }
  };

  const handleBackSwipe = () => {
    if (dir === "ltr") {
      if (activeStep > 0) setActiveStep((state) => state - 1);
    } else {
      if (activeStep < cards.length - 1) setActiveStep((state) => state + 1);
    }
  };

  const handleNext = () => {
    if (activeStep < cards.length - 1) setActiveStep((state) => state + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextSwipe,
    onSwipedRight: handleBackSwipe,
  });

  return (
    <Box component="div" {...handlers}>
      {cards[activeStep]}
      {cards.length > 1 && (
        <MobileStepper
          steps={cards.length}
          position="static"
          activeStep={activeStep}
          sx={{ paddingTop: 0 }}
          nextButton={
            <Button
              size="small"
              variant="text"
              onClick={handleNext}
              disabled={activeStep === cards.length - 1}
              sx={{ padding: 0 }}
            >
              <DirectionArrow fontSize="large" reverse={i18n.language === "en"} />
            </Button>
          }
          backButton={
            <Button
              size="small"
              variant="text"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ padding: 0, maxWidth: "fit-content" }}
            >
              <DirectionArrow fontSize="large" reverse={i18n.language === "ar"} />
            </Button>
          }
        />
      )}
    </Box>
  );
};

CardSlider.propTypes = {
  cards: propTypes.array.isRequired,
};

export default CardSlider;
