import ApplePayIntegration from "./ApplePayIntegration";
import { HyperPayPayment } from "./HyperPayPayment";
import { TabbyTamaraPayment } from "./TabbyTamaraPayment";

export const getPaymentMethods = ({ packageData, availableMethods }) => {
  const paymentMethodsConfig = {
    mada: true,
    visa_master: true,
    tabby: !packageData.automaticRenew && packageData.itemType === "paymentPlan",
    tamara: !packageData.automaticRenew && packageData.itemType === "paymentPlan",
    apple_pay: !packageData.automaticRenew && packageData.itemType === "paymentPlan",
  };

  return availableMethods.filter((method) => paymentMethodsConfig[method]);
};

export const initialPaymentState = {
  loading: false,
  selectedPackage: null,
  modalOpen: false,
  method: null,
  error: null,
};

export const paymentReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_MODAL_OPEN":
      return { ...state, modalOpen: action.payload };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalOpen: false,
        selectedPackage: null,
        method: null,
        loading: false,
      };
    case "SET_SELECTED_PACKAGE":
      return { ...state, selectedPackage: action.payload };
    case "SET_PAYMENT_METHOD":
      return { ...state, method: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    case "INITIATE_APPLE_PAY":
      return {
        ...state,
        loading: true,
        modalOpen: false,
        selectedPackage: null,
        method: null,
      };
    case "CANCEL_APPLE_PAYMENT":
      return {
        ...state,
        loading: false,
        modalOpen: false,
        selectedPackage: null,
        method: null,
      };
    default:
      return state;
  }
};

export const pay = ({ config, dispatch, apiClient }) => {
  switch (config.payment_method) {
    case "tabby":
      new TabbyTamaraPayment({
        config,
        dispatch,
        apiClient,
        endpoint: "/tabby/payment",
      }).pay();
      break;
    case "tamara":
      new TabbyTamaraPayment({
        config,
        dispatch,
        apiClient,
        endpoint: "/tamara/payment",
      }).pay();
      break;
    case "visa_master":
    case "mada":
      new HyperPayPayment({ config, dispatch, apiClient }).pay();
      break;
    case "apple_pay":
      new ApplePayIntegration({ config, dispatch, apiClient }).pay();
      break;
    default:
      dispatch({ type: "SET_ERROR", payload: "Invalid payment method" });
  }
};
