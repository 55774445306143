import React from "react";
import { useOutletContext } from "react-router-dom";

import { useMediaQuery } from "@mui/material";

import UserProfileSkeleton from "../../../components/Skeletons/UserProfileSkeleton";
import { useSaudiCities, useSaudiStates } from "../../../hooks";
import ProfileForm from "./ProfileForm";

const UserProfile = () => {
  const { user, apiClient } = useOutletContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { data: statesData } = useSaudiStates({ apiClient });

  const { data: citiesData } = useSaudiCities({ apiClient });

  if (!user || !statesData || !citiesData)
    return <UserProfileSkeleton isDesktop={isDesktop} isMobile={isMobile} />;
  else return <ProfileForm />;
};

export default UserProfile;
