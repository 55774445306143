import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { Box, useTheme } from "@mui/material";

import PageHeader from "../../components/PageHeader";
import SettingsNavbar from "../../components/Settings/SettingsNavbar";

const Settings = () => {
  const { t } = useTranslation();
  const context = useOutletContext();

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/settings" || location.pathname === "/settings/")
      navigate("/settings/user-profile");
  }, [location.pathname, navigate]);

  return (
    <Box>
      <PageHeader title={t("settings")} />
      <Box
        sx={{
          padding: theme.spacing(2, 3),
          marginTop: theme.spacing(3),
          border: "1px solid",
          borderRadius: 1,
          borderColor: "customColors.border",
        }}
      >
        <SettingsNavbar />
        <Outlet context={context} />
      </Box>
    </Box>
  );
};

export default Settings;
