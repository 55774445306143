import React from "react";
import { useTranslation } from "react-i18next";
import { Link as routerLink } from "react-router-dom";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Box, Link, Typography } from "@mui/material";
import propTypes from "prop-types";

const ClassTrainer = ({ trainer, classID, nextLinkState }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderInlineStart: "2px solid",
        borderColor: "primary.main",
        padding: (theme) => theme.spacing(0, 1),
      }}
    >
      <Typography variant="subtitle2" component="h3">
        {t("trainer")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {trainer.photoUrl ? (
          <Box
            component="img"
            src={trainer.photoUrl}
            alt="Instructor Photo"
            sx={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: 1,
              marginInlineEnd: 1,
            }}
          />
        ) : (
          <AccountBoxIcon sx={{ width: "45px", height: "45px" }} />
        )}
        <Box>
          <Link
            component={routerLink}
            to={`/trainer/${trainer.id}`}
            state={{ prevRoute: `/classes/${classID}`, nextLinkState: nextLinkState }}
          >
            <Typography variant="link" fontWeight={600}>
              {`${trainer.firstName} ${trainer.lastName}`}
            </Typography>
          </Link>
          {/* <Typography variant="body2" fontSize={12}>
            {trainer.email}
          </Typography> */}
        </Box>
      </Box>
      {/* {trainer.bio && (
        <Typography variant="body2" sx={{ margin: (theme) => theme.spacing(1, 0) }}>
          {trainer.bio}
        </Typography>
      )} */}
      {/* {trainer.qualifications?.length > 0 && (
        <Typography variant="body2" sx={{ margin: (theme) => theme.spacing(1, 0) }}>
          {trainer.qualifications}
        </Typography>
      )} */}
    </Box>
  );
};

ClassTrainer.propTypes = {
  trainer: propTypes.shape({
    id: propTypes.number.isRequired,
    firstName: propTypes.string.isRequired,
    lastName: propTypes.string.isRequired,
    // email: propTypes.string.isRequired,
    photoUrl: propTypes.string,
    // bio: propTypes.string,
    // qualifications: propTypes.string,
  }),
  classID: propTypes.string.isRequired,
  nextLinkState: propTypes.object,
};

export default ClassTrainer;
