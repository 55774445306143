import React from "react";

import { Box, Skeleton } from "@mui/material";

const PaymentCardSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="30%" />
      <Skeleton variant="text" width="20%" />
    </Box>
  );
};

export default PaymentCardSkeleton;
