import React from "react";
import { Controller } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { FormHelperText } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import propTypes from "prop-types";

const FormDatePicker = ({ control, name, rules, sx }) => {
  const { dateFnsLocale } = useOutletContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState, formState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
          <DatePicker
            disableFuture
            sx={{
              ...sx,
              "& .MuiOutlinedInput-root": (theme) => ({
                "& fieldset": {
                  borderColor: `${formState.errors?.dob ? theme.palette.error.main : "rgba(51, 51, 51, 0.3)"} !important`,
                },
                "&:hover fieldset": {
                  borderColor: "rgba(51, 51, 51, 1)  !important",
                },
                "&:active fieldset, &.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main + " !important",
                },
              }),
            }}
            value={value}
            onChange={(val) => onChange(format(val, "yyyy-MM-dd"))}
          />
          <FormHelperText error={!!formState.errors?.dob}>
            {formState.errors.dob?.message || ""}
          </FormHelperText>
        </LocalizationProvider>
      )}
    />
  );
};

FormDatePicker.propTypes = {
  control: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
  rules: propTypes.object,
  sx: propTypes.object,
};

export default FormDatePicker;
