import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Box, Typography, useMediaQuery } from "@mui/material";

import CardSlider from "../../../components/CardSlider/CardSlider";
import EmptyComponent from "../../../components/EmptyComponent";
import PaymentCardSkeleton from "../../../components/Skeletons/PaymentCardSkeleton";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import PaymentCard from "../../../components/TransactionHistory/PaymentCard";
import TransactionsTable from "../../../components/TransactionHistory/TransactionsTable";
import { useTransactionHistory } from "../../../hooks";

const LatestPayments = () => {
  const { t } = useTranslation();
  const { apiClient, user } = useOutletContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [latestTransactions, setLatestTransactions] = useState([]);
  const [mobileCards, setMobileCards] = useState([]);
  const { data: transactionsData, isPending: isTransactionsPending } = useTransactionHistory({
    apiClient,
    memberId: user?.id,
  });

  useEffect(() => {
    const latestItems = transactionsData.slice(0, 3);

    setLatestTransactions(latestItems);
    setMobileCards(latestItems.map((item) => <PaymentCard key={item.id} payment={item} />));
  }, [transactionsData]);

  let element = null;

  if (isTransactionsPending) {
    if (isMobile) element = <PaymentCardSkeleton />;
    else element = <TableSkeleton />;
  }

  if (!isTransactionsPending && latestTransactions.length) {
    if (isMobile) element = <CardSlider cards={mobileCards} />;
    else element = <TransactionsTable transactions={latestTransactions} />;
  }

  if (!isTransactionsPending && !latestTransactions.length) {
    element = <EmptyComponent text={t("settingsModule.noTransactions")} />;
  }

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h5" component="h2" fontWeight="fontWeightBold" sx={{ marginBottom: 1 }}>
        {t("dashboardModule.latestPayments")}
      </Typography>
      {element}
    </Box>
  );
};

export default LatestPayments;
