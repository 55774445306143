import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import { useBranding } from "../../hooks";

const ErrorPage = () => {
  const { t } = useTranslation();
  const branding = useBranding();

  return (
    <Box
      sx={{
        height: window.innerHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      {branding && (
        <Box
          component="img"
          src={branding.assets?.darkLogo}
          alt={branding.logoAltText}
          sx={{ position: "absolute", top: "20px" }}
        />
      )}

      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          padding: (theme) => theme.spacing(3, 2),
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          fontWeight="fontWeightBold"
          fontFamily="Changa, sans-serif"
          sx={{ marginTop: 3, fontSize: "6rem" }}
        >
          404
        </Typography>
        <Typography
          variant="h2"
          component="subttitle1"
          color="gray.dark"
          fontWeight="fontWeightMedium"
        >
          {t("errorPageMsg")}
        </Typography>
        <Button variant="contained" component={Link} to="/" sx={{ width: "170px", marginTop: 3 }}>
          {t("continue")}
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
