import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";

const DayCard = ({ day, date, isActive, onClick }) => {
  const { i18n } = useTranslation();

  return (
    <Box
      color="primary"
      component="button"
      onClick={onClick}
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.palette.grey[200],
        borderRadius: 2,
        padding: (theme) => theme.spacing(1, 2),
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: isActive ? "primary.contrastText" : "primary.main",
        bgcolor: isActive ? "primary.main" : "transparent",
        cursor: "pointer",
        "&:hover": {
          color: "primary.contrastText",
          bgcolor: "primary.main",
        },

        "&:active": {
          color: "primary.contrastText",
          bgcolor: "primary.dark",
        },
      }}
    >
      <Typography
        variant="subtitle1"
        fontSize={i18n.language === "ar" ? 12 : 14}
        fontWeight="fontWeightBold"
        component="h6"
      >
        {i18n.language === "ar" ? "ال" + day : day}
      </Typography>
      <Typography variant="subtitle1" fontWeight="fontWeightBold" component="h6">
        {date}
      </Typography>
    </Box>
  );
};

DayCard.propTypes = {
  day: propTypes.string.isRequired,
  date: propTypes.string.isRequired,
  isActive: propTypes.bool,
  onClick: propTypes.func,
};

export default DayCard;
