import React from "react";
import { useOutletContext } from "react-router-dom";

import { Box, Skeleton } from "@mui/material";

const TableSkeleton = () => {
  const {
    viewport: { isMobile, isTablet },
  } = useOutletContext();
  const headersCount = isMobile ? 2 : isTablet ? 4 : 8;

  return (
    <Box sx={{ marginTop: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {Array.from({ length: headersCount }).map((_, index) => (
          <HeaderCellSkeleton key={index} />
        ))}
      </Box>
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
    </Box>
  );
};

export default TableSkeleton;

const RowSkeleton = () => (
  <Skeleton variant="rectangular" height={40} sx={{ marginTop: 1 }} animation="wave" />
);

const HeaderCellSkeleton = () => <Skeleton variant="text" width={100} animation="wave" />;
