import { overrideButton } from "./styleOverrides";

const generalThemeConfig = {
  palette: {
    primary: {
      main: "#E04F39",
      light: "#FCEEEC",
      dark: "#C22F19",
    },
    secondary: {
      main: "#E04F391A",
      light: "#E04F39",
      contrastText: "#E04F39",
      dark: "#E04F391A",
    },
    gray: {
      main: "#D9D9D9",
      light: "#EBEBEB",
      contrastText: "#111111",
      dark: "#B3B3B3",
    },
    success: {
      main: "#2BB673",
      light: "#E9FFF4",
    },
    error: {
      main: "#F64B4B",
      light: "#FFE9E9",
    },
    text: {
      primary: "#333333",
      secondary: "#444444",
    },
    customColors: {
      bg1: "#F7FAFF",
      bg2: "#FDF7F6",
      bg3: "#FDFBF6",
      modalBg: "#F9F9F9",
      fileBtnBg: "#EDF2F6",
      fileBtnBorder: "#B2B2B2",
      border: "#EBEBEB",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'sans-serif'",
    fontSize: 14,
    h1: {
      fontSize: "1.6rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.4rem",
    },
    h4: {
      fontSize: "1.3rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
    h6: {
      fontSize: "1.1rem",
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#111",
          color: "#fff",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#111",
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: overrideButton,
      },
      variants: [
        {
          props: { variant: "white" },
          style: ({ theme }) => ({
            backgroundColor: "#FFFFFF",
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }),
        },
        {
          props: { variant: "simpleText" },
          style: ({ theme }) => ({
            color: "#fff",
            padding: 0,
            fontSize: "1rem",
            fontWeight: 600,
            "&:hover, &:active": {
              backgroundColor: "transparent",
              color: theme.palette.primary.main,
            },
          }),
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: "start",
        },
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "textLink" },
          style: {
            fontSize: "13px",
            fontWeight: "600",
            textDecoration: "underline",
          },
        },
        {
          props: { variant: "navlink" },
          style: ({ theme }) => ({
            color: "inherit",
            textDecoration: "none",
            ".MuiListItem-root": {
              ".MuiButtonBase-root": {
                marginTop: 5,
                ".MuiListItemText-primary": {
                  fontWeight: "600",
                },
                ".MuiSvgIcon-root": {
                  display: "none",
                },
              },
            },
            "&:hover, &.active": {
              color: theme.palette.primary.main,
              ".MuiListItem-root": {
                ".MuiButtonBase-root": {
                  ".MuiSvgIcon-root": {
                    display: "inline-block",
                  },
                },
              },
            },
          }),
        },
        {
          props: { variant: "iconbtn" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.gray.light,
            color: "#444",
            padding: "0",
            width: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "2px",
            "&:hover": {
              backgroundColor: theme.palette.gray.main,
            },
          }),
        },
        {
          props: { variant: "tabLink" },
          style: ({ theme }) => ({
            textDecoration: "none",
            color: "inherit",
            fontWeight: "600",
            fontSize: "0.9rem",
            minWidth: "11rem",
            textAlign: "center",
            padding: theme.spacing(1, 0),
            "&.active, &:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
          }),
        },
      ],
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          color: theme.palette.primary.main,
          fontWeight: 600,
        }),
        root: {
          textAlign: "start",
          borderBottom: "none",
          minWidth: "200px",
          maxWidth: "200px",
        },
      },
    },
  },
};

export { generalThemeConfig };
